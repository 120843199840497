import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Card,
  CardContent,
  Collapse,
  Switch,
  TextField,
  Typography,
  Table,
  TableHead,
  TableBody,
  TablePagination,
  TableRow,
  TableCell,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./subscriptions.module.css";
import SearchIcon from "@mui/icons-material/Search";
import { useLocalStorage } from "@uidotdev/usehooks";
import { ReactComponent as Hemberger } from "../assets/icons/Hemberger.svg";
import { ReactComponent as UserIcon } from "../assets/icons/UserIcon.svg";
import Sidebar from "../sidenav/Sidenav";
import { Link } from "react-router-dom";

const SubscriptionsMobile = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSpecDialog, setOpenSpecDialog] = useState(false);
  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedSpecs, setSelectedSpecs] = useState([]);
  const [changedSkills, setChangedSkills] = useState([]);
  const [filteredSkills, setFilteredSkills] = useState([]);
  const [filteredSpecs, setFilteredSpecs] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [actionType, setActionType] = useState("skills");
  const [searchQuery, setSearchQuery] = useState("");
  const [token, setToken] = useLocalStorage('token', null);
  const [skillsCompletion, setSkillsCompletion] = useState([]);
  const userId = localStorage.getItem("userId");
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [updateDialog, setUpdateDialog] = useState(false);
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const displayedUsers = filteredUsers.slice(startIndex, endIndex);
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredUsers.length - page * rowsPerPage);

  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    mobile: "",
  });
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [logoutDialog, setLogoutDialog] = useState(false);

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const handleUpdate = (userId) => {
    const selected = users.find((user) => user.user_id === userId);
    console.log(selected);

    setSelectedUser(selected);
    setFormValues({
      id: selected.user_id,
      name: selected.name,
      email: selected.email,
      mobile: selected.mobile,
    });
    setUpdateDialog(true);
  };

  const handleUpdateDialogClose = () => {
    setUpdateDialog(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleUpdateUser = async (id) => {
    const { name, email, mobile } = formValues;
    console.log(formValues);

    if (!name || !email || !mobile) {
      console.error("All fields are required");
      toast.error("All fields are required");
      return;
    }

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/updateUser/update/${id}`,
        {
          name,
          email,
          mobile,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("User data updated successfully");
        fetchUsers();
        setUpdateDialog(false);
      } else {
        toast.error("Error updating user data: ", response.data.message);
      }
    } catch (error) {
      toast.error("Error updating user data: ", error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/user/all?order=desc`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const reversedUsers = response.data.data.reverse(); // Reverse the array of users
        setUsers(reversedUsers);
        setFilteredUsers(reversedUsers); // Initially set filtered users to all users
      } else {
        console.error("Error fetching users: ", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching users: ", error);
    }
  };

  const fetchSkills = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/skills/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setSkills(response.data.data);
      } else {
        console.error("Error fetching skills: ", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching skills: ", error);
    }
  };

  const fetchCompletionData = async (userId, token) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/user/${userId}/skillCompletion`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Return the completion data
    } catch (error) {
      console.error("Error fetching skills completion data: ", error);
      return null; // Return null in case of error
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchCompletionData(token);
    fetchSkills();
  }, [token]);

  // Function to handle opening the dialog and fetching completion data
  const handleOpenDialog = async (userId, action) => {

    console.log("action ", action);
    try {
      // await fetchSkills(); // Fetch skills first

      if (action === "skills") {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/subscription/skill-data/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data.data);
        const fetchedSkills = response.data.data;
        // const userSkills = response.data.user.skills || [];
        const pNull = fetchedSkills.filter(val => val.is_active === null)?.map(val => val.skill_id)
        // console.log(pNull);
        const sSkills = fetchedSkills?.map(val => val.is_active === null ? false : val.is_active === 0 ? false : true)
        console.log(sSkills);
        const selected = users.find((user) => user.user_id === userId);

        setSelectedUser(selected);
        setSelectedSkills(sSkills)
        setFilteredSkills(fetchedSkills);
        const completionData = await fetchCompletionData(userId, token);

        console.log(completionData !== null || completionData.length !== 0);
        if (completionData !== null) {
          // If completion data is fetched successfully
          setSkillsCompletion(completionData); // Update the state with completion data

          setOpenDialog(true);
        } else {
          // If completion data fetch fails, display an error message or handle gracefully
          throw new Error("Failed to fetch skills completion data.");
        }
      } else
        if (action === "specs") {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/subscription/spec-data/${userId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log(response.data.data);
          const fetchedSpecs = response.data.data;
          // const userSkills = response.data.user.skills || [];
          // console.log(pNull);
          const sSpecs = fetchedSpecs?.map(val => val.is_active === null ? false : val.is_active === 0 ? false : true)
          console.log(sSpecs);
          setSelectedSpecs(sSpecs)
          setFilteredSpecs(fetchedSpecs);
          setOpenSpecDialog(true)
        }
      setSelectedUserId(userId);
      // setSelectedSkills(userSkills);
      setActionType(action);

      // const completionData = await fetchCompletionData(userId, token); // Fetch completion data
      // console.log(completionData);
    } catch (error) {
      console.error(
        "Error opening dialog and fetching completion data: ",
        error
      );
      toast.error(
        "An error occurred while opening the dialog.",
        error.toString()
      );
    }
  };
  console.log(changedSkills);
  const handleUnsubscription = () => {

    if (actionType === "update") {
      updateSkillStatus(changedSkills, 0);
    }

  }

  // useEffect(()=>{
  //   setOpenDialog(true)
  // },[filteredSkills])

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setOpenSpecDialog(false)
    setSelectedUserId(null);
    setSelectedSkills([]);
    setFilteredSkills([]);
    setFilteredSpecs([])
    setSelectedSpecs([]);
    setChangedSkills([]);
  };

  const handleButtonClick = (userId, action) => {
    handleOpenDialog(userId, action);
  };

  const handleResetButtonClick = (userId) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/change-password/${userId}/resetLimit`;

    axios
      .put(apiUrl)
      .then((response) => {
        console.log(response.data.message);
        toast.success("User limit has been reset successfully.");
      })
      .catch((error) => {
        console.error("Error occurred: ", error);
        toast.error(
          error.response?.data?.message ||
          "An error occurred. Please try again later."
        );
      });
  };

  const handleSkillToggle = (e, skillId, ind) => {
    console.log(e.target.checked, skillId, ind, " Skill Toggle");
    console.log(selectedSkills);

    setSelectedSkills((prevSkills) => {
      const newSkills = [...prevSkills]
      newSkills[ind] = !newSkills[ind]

      return newSkills;
    });

  };

  const handleSpecToggle = (e, skillId, ind) => {

    console.log(e.target.checked, skillId, ind);
    console.log(selectedSkills);

    setSelectedSpecs((prevSpecs) => {
      const newSpecs = [...prevSpecs]
      newSpecs[ind] = !newSpecs[ind]

      return newSpecs;
    });

  };

  const updateSkillStatus = (skillId, isActive) => {
    if (skillId.length === 0) {
      toast.error("No skill selected")
      return
    }
    const data = {
      user_id: selectedUserId,
      skill_id: skillId,
      is_active: isActive,
    };

    axios
      .put(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/enrollment/updateStatus`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success("User Unsubscribed sucessfully")
        setOpenDialog(false)
        console.log("Status update success:", response.data);
      })
      .catch((error) => {
        toast.error("Update Failed.");
        console.error("Status update error:", error);
      });
  };

  const handleSubscription = async () => {
    setOpenDialog(false);
    const skillsToInsert = filteredSkills.filter((skill, index) => {
      let active = false;
      if (skill.is_active === null && selectedSkills[index] === true) {
        return true;
      }
      return false

    })?.map(val => {
      return ({
        skillId: val.skill_id,
        isActive: true
      })
    })

    const skillsToUpdate = filteredSkills?.map((skill, index) => {
      if (skill.is_active !== null && skill.is_active != selectedSkills[index]) {
        return ({
          skillId: skill.skill_id,
          isActive: selectedSkills[index]
        })
      }
    }).filter(val => val !== undefined);

    // console.log(skillsToInsert);
    // console.log("Break");
    // console.log(skillsToUpdate);

    if (actionType == "skills") {
      try {
        const response = await axios.put(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/subscription/skill-subscription`, {
          skillsToInsert: skillsToInsert || [],
          skillsToUpdate: skillsToUpdate || [],
          userId: selectedUserId
        })

        if (response.status === 200) {
          toast.success("Skills Updated Sucessfully")
        }
      } catch (error) {
        toast.error("error Updating skills Status");
        console.error(error);

      }
    }
  };

  const handleSpecSubscription = async () => {
    setOpenSpecDialog(false);
    const specToInsert = filteredSpecs.filter((spec, index) => {
      let active = false;
      if (spec.is_active === null && selectedSpecs[index] === true) {
        return true;
      }
      return false

    })?.map(val => {
      return ({
        specId: val.specialization_id,
        isActive: true
      })
    })

    const specsToUpdate = filteredSpecs?.map((spec, index) => {
      if (spec.is_active !== null && spec.is_active != selectedSpecs[index]) {
        return ({
          specId: spec.specialization_id,
          isActive: selectedSpecs[index]
        })
      }
    }).filter(val => val !== undefined);

    const finalArray = specToInsert.concat(specsToUpdate)

    if (actionType == "specs") {
      try {
        const response = await axios.put(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/subscription/specialization-subscription`, {
          specArray: finalArray,
          userId: selectedUserId
        })

        if (response.status === 200) {
          toast.success("Specialization Updated Sucessfully")
        }
      } catch (error) {
        toast.error("error Updating Specialization Status");
        console.error(error);
      }
    }
  };

  // Function to filter users based on search query
  const filterUsers = (query) => {
    if (!query) {
      setFilteredUsers(users);
    } else {
      const filtered = users.filter(
        (user) =>
          user.name.toLowerCase().includes(query.toLowerCase()) ||
          user.username.toLowerCase().includes(query.toLowerCase()) ||
          user.email.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredUsers(filtered);
    }
  };

  useEffect(() => {
    filterUsers(searchQuery);
  }, [searchQuery]);

  if (!isAuthorized) {
    return (
      <div className="container-fluid mt-5">
        <h2 style={{marginTop: '5%'}}>You are unauthorized to access this page</h2>
      </div>
    );
  }

  function formatDate(date) {
    date = new Date(date);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let formattedDate = `${day}.${month}.${year}`;
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let AmOrPm = hours >= 12 ? 'pm' : 'am';
    let formattedTime = `${hours}:${minutes}${AmOrPm}`;
    return { time: formattedTime, date: formattedDate };
  }

  /* MUI Table */
  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow>
          <TableCell style={{maxWidth: '20ch', wordWrap:'break-word'}}>
            <div>{row?.name}</div>
            <div>{row?.username}</div>
          </TableCell>
          <TableCell align="right">
            <div>{formatDate(row?.created_at).date}</div>
            <div>{formatDate(row?.created_at).time}</div>
          </TableCell>
          <TableCell>
            <IconButton
              aria-label="expand row"
              style={{ color: 'black' }}
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow sx={{ "& td": { border: 0, height: open ? 'auto' : 0 }, borderBottom: '1px solid black', }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderTop: 'none' }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit  >
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 150px', rowGap: '6px', fontSize: '10px', marginTop: '4px', marginBottom: '4px' }}>
                <div>Email: {row?.email}</div>
                <div>Mobile: {row?.mobile}</div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '10px', marginBottom: '5px' }}>
                <div style={{ marginRight: '0.5rem' }}>Action:</div>
                <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'end', gap: '9px', flex: '1' }}>
                  <Button
                    onClick={() =>
                      handleButtonClick(row.user_id, "specs")
                    }
                    className={styles.actionBtn}
                    style={{
                      backgroundColor: "#FFD6D2",
                    }}
                  >
                    Specialization
                  </Button>
                  <Button
                    onClick={() =>
                      handleButtonClick(row.user_id, "skills")
                    }
                    className={styles.actionBtn}
                    style={{
                      backgroundColor: "#D1F4DE"
                    }}
                  >
                    Skills
                  </Button>
                  <Button
                    onClick={() => handleResetButtonClick(row.user_id)}
                    className={styles.actionBtn}
                    style={{
                      backgroundColor: "#FCF6BC",
                      padding: '0'
                    }}
                  >
                    Reset Limits
                  </Button>
                  <Button
                    onClick={() => {
                      handleUpdate(row.user_id);
                    }}
                    className={styles.actionBtn}
                    style={{
                      backgroundColor: "#CBEBF6",
                    }}
                  >
                    Update
                  </Button>
                </div>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '0.5rem 0.75rem 1.25rem 0.75rem' }}>
        <div onClick={() => setIsSidebarOpen(!isSidebarOpen)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', width: '36px', height: '36px', borderRadius: '18px', background: '#EAF1F7' }}>
          <Hemberger />
        </div>
        <Link to="/profile">
          <UserIcon />
        </Link>
      </div>
      <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} setLogoutDialog={setLogoutDialog} />

      <Card style={{ boxShadow: 'none', padding: '0' }}>
        <CardContent style={{ padding: '0' }}>
          <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', margin: '0rem 0.75rem 1.5rem 0.75rem' }}>
            <h4>Subscriptions</h4>
            <TextField
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent',
                    boxShadow: 'none',
                  },
                },
              }}
              InputProps={{
                style: {
                  height: '1.75rem',
                  width: '9.75rem',
                  boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A',
                  padding: '0',
                },
                endAdornment: (
                  // <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                  // </InputAdornment>
                ),
              }}
            />
          </div>

          {/* Pagination */}
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              '& .MuiTablePagination-input': {
                marginRight: '0.5rem',
                marginLeft: '0',
              }
            }}
          />
          <ToastContainer />

          {/* MUI Table */}
          <Table aria-label="collapsible table" sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none"
            }
          }}>
            <TableHead style={{ height: '2.564vw', borderRadius: '4px !important', background: 'rgba(234, 241, 247, 1)', boxShadow: '2px 2px 4px 0px rgba(174, 174, 192, 0.5)' }}>
              <TableRow>
                <TableCell>Name & Username</TableCell>
                <TableCell align="right">Date & Time</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedUsers
                ?.map((user, ind) => (
                  <Row key={ind} row={user} />
                ))}
            </TableBody>

            {/* Skills Dialog */}
            <Dialog open={openDialog} onClose={handleCloseDialog}
              PaperProps={{
                style: {
                  padding: '1.5rem',
                  borderRadius: '0.3rem',
                  boxShadow: '4px 4px 8px 0px #AEAEC099, box-shadow: -4px -4px 8px 0px #FFFFFF80',
                  overflowX: 'hidden'
                }
              }}
            >
              <DialogTitle style={{ paddingLeft: '0', fontWeight: '600', paddingTop: '0' }}>Student Skill Management</DialogTitle>
              <div className="dialog-content">
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1.282vw' }}>
                  <div style={{ fontSize: '0.75rem', fontWeight: '500' }}>
                    Name: {selectedUser.name}
                  </div>
                  <div style={{ fontSize: '0.75rem', fontWeight: '500' }}>
                    Username: {selectedUser.username}
                  </div>
                </div>
                <table className="table">
                  <thead className={styles.dialogTableHead}>
                    <tr>
                      <th style={{ textAlign: 'left', fontSize: '0.75rem' }}>Skill Name</th>
                      <th style={{fontSize: '0.75rem'}}>Completion %</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredSkills?.map((skill, index) => (
                      <tr key={skill.skill_id}>
                        <td style={{ textAlign: 'left' }}>{skill.skill_name}</td>
                        <td>
                          <Typography style={{ fontSize: "0.8125rem" }}>
                            {Math.floor(
                              skillsCompletion.find(
                                (completion) =>
                                  completion.skill_id === skill.skill_id
                              )?.completion_percentage || 0
                            )}
                            %
                          </Typography>
                        </td>
                        <td>
                          <Switch
                            value={skill.skill_id}
                            onChange={(e) => handleSkillToggle(e, skill.skill_id, index)}
                            checked={selectedSkills[index]}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {actionType === "skills" && (
                <div className={styles.buttonContainer}>
                  <Button
                    onClick={handleCloseDialog}
                    className="formBtnMobile"
                    style={{
                      color: 'black',
                      border: '2px solid #313C63',
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubscription}
                    className="formBtnMobile"
                    style={{
                      backgroundColor: '#37446F',
                      color: 'white'
                    }}
                  >
                    Update Skills
                  </Button>
                </div>
              )}
              {actionType === "update" && (
                <div style=
                  {{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}>
                  <Button
                    onClick={handleCloseDialog}
                    className="formBtnMobile"
                    style={{
                      border: '2px solid #313C63',
                      color: '#000000'
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleUnsubscription}
                    className="formBtnMobile"
                    style=
                    {{
                      color: '#ffffff',
                      backgroundColor: '#37446F'
                    }}
                  >
                    Unsubscribe
                  </Button>
                </div>
              )}
            </Dialog>

            {/* Specialization Dialog */}
            <Dialog
              open={openSpecDialog}
              onClose={handleCloseDialog}
              PaperProps={{
                style: {
                  padding: '1.5rem',
                  borderRadius: '0.3rem',
                  boxShadow: '4px 4px 8px 0px #AEAEC099, box-shadow: -4px -4px 8px 0px #FFFFFF80'
                }
              }}
            >
              <DialogTitle style={{ paddingLeft: '0', fontWeight: '600', paddingTop: '0' }}>Add Specialization</DialogTitle>
              <div className="dialog-content">
                <table className="table">
                  <thead className={styles.dialogTableHead}>
                    <tr>
                      <th style={{ textAlign: 'left', fontSize: '0.75rem' }}>Specialization Name</th>
                      {/* <th>Completion %</th> */}
                      <th style={{fontSize: '0.75rem'}}>Toggle</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredSpecs?.map((spec, index) => (
                      <tr key={spec.specialization_id}>
                        <td style={{ textAlign: 'left' }}>{spec.specialization_name}</td>
                        <td>
                          <Switch
                            value={spec.specialization_id}
                            onChange={(e) => handleSpecToggle(e, spec.specialization_id, index)}
                            checked={selectedSpecs[index]}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* {actionType === "specs" && ( */}
                <div className={styles.buttonContainer}>
                  <Button
                    onClick={handleCloseDialog}
                    className="formBtnMobile"
                    style={{
                      border: '2px solid #313C63',
                      color: '#000000',
                      flex: '1',
                      fontsize: '0.75rem !important'
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSpecSubscription}
                    className="formBtnMobile"
                    style={{
                      backgroundColor: '#37446F',
                      color: 'white',
                      fontsize: '0.75rem !important'
                    }}
                  >
                    Update Specialization
                  </Button>
                </div>
              {/* )} */}
            </Dialog>

            {/* Update User Details */}
            <Dialog open={updateDialog} onClose={handleUpdateDialogClose}>
              <DialogTitle>Update User Details</DialogTitle>
              <form className="m-2" style={{ display: 'flex', flexDirection: 'column', padding: '4px' }}>
                <TextField
                  type="text"
                  label="Name"
                  name="name"
                  variant="outlined"
                  value={formValues.name}
                  onChange={handleFormChange}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                      '&:hover fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                        boxShadow: 'none',
                      },
                    },
                    marginBottom: '1rem'
                  }}
                  InputLabelProps={{
                    sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                    required: false
                  }}
                  InputProps={{
                    style: {
                      boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                    },
                  }}
                />
                <TextField
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                      '&:hover fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                        boxShadow: 'none',
                      },
                    },
                    marginBottom: '1rem'
                  }}
                  InputLabelProps={{
                    sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                    required: false
                  }}
                  InputProps={{
                    style: {
                      boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                    },
                  }}
                  type="email"
                  label="Email"
                  name="email"
                  value={formValues.email}
                  onChange={handleFormChange}
                />
                <TextField
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                      '&:hover fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                        boxShadow: 'none',
                      },
                    },
                    marginBottom: '1rem'
                  }}
                  InputLabelProps={{
                    sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                    required: false
                  }}
                  InputProps={{
                    style: {
                      boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                    },
                  }}
                  type="phone"
                  label="mobile"
                  name="mobile"
                  value={formValues.mobile}
                  onChange={handleFormChange}
                />
                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: "center", gap: '0.5rem' }}>
                  <Button
                    className="formBtnMobile"
                    onClick={handleUpdateDialogClose}
                    style={{
                      border: '2px solid #313C63',
                      color: 'black',
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="formBtnMobile"
                    onClick={() => {
                      handleUpdateUser(formValues.id);
                    }}
                    style={{
                      backgroundColor: '#37446F',
                      color: 'white',
                    }}
                  >
                    Update
                  </Button>
                </div>
              </form>
            </Dialog>
          </Table>
        </CardContent>
      </Card>
    </>
  );
};

export default SubscriptionsMobile;
