import {
  Button,
  Card,
  CardContent,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { ArrowBackIosNewRounded } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import { tableCellClasses } from "@mui/material/TableCell";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Outlet, useNavigate, useParams, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import styles from "./BlogsMobile.module.css";
import axios from "axios";
import TextEditor from "../Components/TextEditor/TextEditor";
import { ReactComponent as Hemberger } from "../assets/icons/Hemberger.svg";
import { ReactComponent as UserIcon } from "../assets/icons/UserIcon.svg";
import Sidebar from "../sidenav/Sidenav";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BlogsMobile = () => {
  const [isSuccessfullAddCalled, setIsSuccessfullAddCalled] = useState(false);
  /* Delete Dialog Box */
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const handleDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  /* on Successfull Update/Add Dialog */
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    setIsSuccessfullAddCalled(false);
  };

  const { catId } = useParams();
  const [isOpen, setIsOpen] = useState({
    add: false,
    update: false,
  });
  const [blogsData, setBlogsData] = useState([]);
  const [subTitle, setSubTitle] = useState([]);
  const [selectedBlogId, setSelectedBlogId] = useState("")

  const [blogsDetails, setBlogsDetails] = useState({
    title: "",
    order: "",
    author: "",
    imageURL: "",
    description: "",
    shortDescription: "",
    summary: "",
  });
  // const [blogDetails,setBlogDetails]=useState({

  // })
  const getAllBlogsData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/blogs/${catId}`
      );
      if ((response.status = 200)) {
        console.log(response.data.data);
        setBlogsData(response.data.data);
      }
    } catch (error) {
      console.log(error.response.data.error);

      toast.error(error.response.data.message);
    }
  };

  const addBlog = async () => {
    if(!blogsDetails.title || !blogsDetails.order || !blogsDetails.author || !blogsDetails.imageURL || !blogsDetails.description || !blogsDetails.shortDescription || !blogsDetails.summary){
      toast.error("Please fill all the fields")
      return
    }
    // console.log(blogsDetails, " addBlog");
    // setOpen(true); // just for demo remove it
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/blog`, {
        title: blogsDetails.title,
        order: blogsDetails.order,
        author: blogsDetails.author,
        imageURL: blogsDetails.imageURL,
        description: blogsDetails.description,
        shortDescription: blogsDetails.shortDescription,
        summary: blogsDetails.summary,
        subtitle: subTitle.map(sub => sub.title),
        subtitleDesc: subTitle.map(sub => sub.description),
        categoryId: catId
      }
      );

      if (response.status === 200) {
        // toast.success("Blog Added Sucessfully")
        setOpen(true);
        setIsSuccessfullAddCalled(true);
        getAllBlogsData()
        handleCloseAddDialog()
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message)

    }
  }


  const editBlog = async (id) => {
    console.log(id, " editBlog");
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/blog/${id}`)
      console.log(response);
      setBlogsDetails({
        title: response.data.data.blog_title,
        order: response.data.data.article_order,
        author: response.data.data.author,
        imageURL: response.data.data.image_url,
        description: response.data.data.detailed_description || "",
        shortDescription: response.data.data.short_description || "",
        summary: response.data.data.summary,
      })
      setSelectedBlogId(response.data.data.blog_id)
      let temp = []
      if (response.data.data.subtitle_titles) {
        const descriptionData = JSON.parse(response.data.data.subtitle_descriptions)
        temp = JSON.parse(response.data.data.subtitle_titles).map((val, ind) => {
          return {
            title: val,
            description: descriptionData[ind]
          }
        })
      }
      console.log(response.data.data.subtitle_titles);

      setSubTitle(temp)
      setIsOpen(prev => ({
        ...prev,
        update: true
      }))
    } catch (error) {
      console.error(error);

    }
  }

  const updateBlog = async () => {
    if(!blogsDetails.title || !blogsDetails.order || !blogsDetails.author || !blogsDetails.imageURL || !blogsDetails.description || !blogsDetails.shortDescription || !blogsDetails.summary){
      toast.error("Please fill all the fields")
      return
    }
    // setOpen(true); // just for demo remove it
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/blog/${selectedBlogId}`, {
        title: blogsDetails.title,
        order: blogsDetails.order,
        author: blogsDetails.author,
        imageURL: blogsDetails.imageURL,
        description: blogsDetails.description,
        shortDescription: blogsDetails.shortDescription,
        summary: blogsDetails.summary,
        subtitle: subTitle.map(sub => sub.title),
        subtitleDesc: subTitle.map(sub => sub.description),
        categoryId: catId
      }
      );

      if (response.status === 200) {
        // toast.success("Blog Updated Sucessfully")
        setOpen(true);
        getAllBlogsData()
        handleCloseAddDialog()
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message)

    }
  }
  const [categorySelectedForDelete, setCategorySelectedForDelete] = useState(null);
  const deleteBlog = async (id) => {
    // console.log(id, " deleteBlog");
    // setOpenDeleteDialog(false); // remove this just for demo
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/blog/${id}`)

      if (response.status === 200) {
        toast.success("Blog Deleted Sucessfully")
        getAllBlogsData()
        handleCloseAddDialog()
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message)
    }
  }

  const onDescriptionChange = (value) => {
    setBlogsDetails((prev) => ({
      ...prev,
      description: value,
    }));
  };

  const onShortDescriptionChange = (value) => {
    setBlogsDetails((prev) => ({
      ...prev,
      shortDescription: value,
    }));
  };

  const onSummaryChange = (value) => {
    setBlogsDetails((prev) => ({
      ...prev,
      summary: value,
    }));
  };

  const onInputChange = (e) => {
    const name = e.target.name;
    console.log(name);

    setBlogsDetails((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  };

  useEffect(() => {
    getAllBlogsData();
  }, []);

  const handleCloseAddDialog = () => {
    setSelectedBlogId("")
    setIsOpen(
      {
        update: false,
        add: false
      }
    );
    setOpenDeleteDialog(false);
  };

  const onAddPress = () => {
    setBlogsDetails({
      title: "",
      order: "",
      author: "",
      imageURL: "",
      description: "",
      shortDescription: "",
      summary: "",
    });
    setSubTitle([]);
    setIsOpen((prev) => ({
      ...prev,
      add: true,
    }));
  };
  console.log(blogsDetails.description);

  const addSubtitle = () => {
    setSubTitle((prev) => [
      ...prev,
      {
        title: "",
        description: "",
      },
    ]);
  };

  const onSubtitleChange = (e, index) => {
    console.log(subTitle);
    console.log(e.target.value, index);

    setSubTitle((prev) => {
      const temp = [...prev];
      temp[index].title = e.target.value;
      return temp;
    });
  };

  const onSubDescriptionChange = (value, index) => {
    console.log(subTitle);
    console.log(value, index);

    setSubTitle((prev) => {
      const temp = [...prev];
      temp[index].description = value;
      return temp;
    });
  };

  const onDeleteSubtitle = (ind) => {
    console.log(ind);
    console.log(subTitle);

    setSubTitle((prev) => {
      const temp = prev.filter((val, index) => {
        return ind !== index
      })
      console.log(temp);

      return temp;
    })
  }

  /* MUI Table */
  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell component="th" scope="row">
            <div>{row?.blog_title}</div>
          </TableCell>
          <TableCell style={{ textAlign: 'end' }}>
            <IconButton
              aria-label="expand row"
              style={{ color: 'black' }}
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow sx={{ "& td": { border: 0, height: open ? 'auto' : 0 }, borderBottom: '1px solid black', }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderTop: 'none' }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit  >
              <div style={{ fontSize: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                <div style={{ flex: '1' }}>Actions:</div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.4rem' }}>
                  <Button
                    onClick={() => { editBlog(row?.blog_id) }}
                    className="actionMobileBtn"
                    style={{
                      backgroundColor: "#D1F4DE",
                      color: 'black',
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={() => { setCategorySelectedForDelete(row?.blog_id); handleDeleteDialog() }}
                    className="actionMobileBtn"
                    style={{
                      backgroundColor: "#FFD6D2",
                      color: 'black'
                    }}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [logoutDialog, setLogoutDialog] = useState(false);
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };
  const handleBack = () => {
    navigate('/blogs');
  }
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '0.5rem 0.75rem 1.25rem 0.75rem' }}>
        <div onClick={() => setIsSidebarOpen(!isSidebarOpen)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', width: '36px', height: '36px', borderRadius: '18px', background: '#EAF1F7' }}>
          <Hemberger />
        </div>
        <Link to={'/profile'}>
          <UserIcon />
        </Link>
      </div>
      <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} setLogoutDialog={setLogoutDialog} />

      <div className="container-fluid">
        <div className={styles.header}>
          <h4 style={{display: 'flex', alignItems: 'center', gap: '0.2rem'}}>
            <ArrowBackIosNewRounded onClick={handleBack} style={{cursor: 'pointer'}}/> Article
          </h4>
          <Button
            className="button-two"
            style={{
              width: '6rem', height: '1.75rem', background: '#37446F'
            }}
            onClick={() => {
              onAddPress();
            }}
          >
            Add
          </Button>
        </div>


        {/* <table className={styles.mainTable}>
          <thead className={styles.tableHead}>
            <tr>
              <th
                style={{
                  textAlign: "left",
                  paddingLeft: "2vw",
                  width: "20%",
                }}
              >
                S. No.
              </th>
              <th style={{ width: "50%", textAlign: "left" }}>Name</th>
              <th
                style={{
                  textAlign: "left",
                  paddingLeft: "5.128vw",
                  width: "fit-content",
                }}
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {blogsData.map((blog) => (
              <tr key={blog?.blog_id} className={styles.tableBody}>
                <td
                  style={{
                    textAlign: "left",
                    paddingLeft: "3vw",
                    width: "20%",
                  }}
                >
                  {blog?.blog_id}
                </td>
                <td style={{ textAlign: "left" }}>{blog?.blog_title}</td>
                <td style={{ paddingLeft: "5.128vw" }}>
                  <div className={styles.buttonContainer}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={() => { editBlog(blog?.blog_id) }}
                      style={{
                        backgroundColor: "#D1F4DE",
                        height: "2.051vw",
                        width: "7.179vw",
                        color: "black",
                        textTransform: "capitalize",
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={() => { deleteBlog(blog?.blog_id) }}
                      style={{
                        backgroundColor: "#FFD6D2",
                        height: "2.051vw",
                        width: "7.179vw",
                        color: "black",
                        textTransform: "capitalize",
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table> */}

        <Table aria-label="collapsible table" sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none"
          }
        }}>
          <TableHead style={{ height: '44px', borderRadius: '4px !important', background: 'rgba(234, 241, 247, 1)', boxShadow: '2px 2px 4px 0px rgba(174, 174, 192, 0.5)' }}>
            <TableRow>
              <TableCell>Name</TableCell>
              {/* <TableCell align="right">Institute type & City</TableCell> */}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {blogsData
              ?.map((blog, ind) => (
                <Row key={ind} row={blog} />
              ))}
          </TableBody>
        </Table>


      </div>

      {/* Add Update Blog */}
      <Dialog
        open={isOpen.add || isOpen.update}
        onClose={handleCloseAddDialog}
        PaperProps={{
          style: {
            width: "95vw",
            borderRadius: "0.4rem",
            padding: "1.2rem 1rem",
            boxShadow: "4px 4px 8px 0px #AEAEC099, box-shadow: -4px -4px 8px 0px #FFFFFF80",
            margin: '0',
          },
        }}
      >
        <DialogTitle
          style={{
            fontWeight: "600",
            padding: '0'
          }}
        >
          {isOpen.add ? "Add New Article" : "Edit Article"}
        </DialogTitle>
        <div style={{
          padding: '0',
          marginTop: '2.25rem'
        }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1.8rem' }}>
            <TextField
              type="text"
              label={"New Article Name"}
              value={blogsDetails.title}
              name="title"
              onChange={onInputChange}
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent',
                    boxShadow: 'none',
                  },
                },
              }}
              InputProps={{
                style: {
                  boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                },
              }}
              InputLabelProps={{
                sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                required: false
              }}
            />
            <TextField
              type="text"
              name="order"
              label={"Article Order"}
              onChange={onInputChange}
              value={blogsDetails.order}
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent',
                    boxShadow: 'none',
                  },
                },
              }}
              InputProps={{
                style: {
                  boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                },
              }}
              InputLabelProps={{
                sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                required: false
              }}
            />
            <TextField
              type="text"
              name="author"
              label={"Author"}
              onChange={onInputChange}
              value={blogsDetails.author}
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent',
                    boxShadow: 'none',
                  },
                },
              }}
              InputProps={{
                style: {
                  boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                },
              }}
              InputLabelProps={{
                sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                required: false
              }}
            />
            <TextField
              type="text"
              name="imageURL"
              label={"Image URL"}
              onChange={onInputChange}
              value={blogsDetails.imageURL}
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent',
                    boxShadow: 'none',
                  },
                },
              }}
              InputProps={{
                style: {
                  boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                },
              }}
              InputLabelProps={{
                sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                required: false
              }}
            />
            <div className={styles.shortDescriptionContainer}>
              <TextEditor
                propsContent={blogsDetails.shortDescription}
                propPlaceholder={"Short Description...."}
                onChangeContent={onShortDescriptionChange}
              />
            </div>
            <div className={styles.descriptionContainer}>
              <TextEditor
                propsContent={blogsDetails.description}
                propPlaceholder={"Detailed Description..."}
                onChangeContent={onDescriptionChange}
              />
            </div>
            <div className={styles.summaryContainer}>
              <TextEditor
                propsContent={blogsDetails.summary}
                propPlaceholder={"Summary..."}
                onChangeContent={onSummaryChange}
              />
            </div>
            {subTitle.map((sub, index) => (
              <div key={index} className={styles.subtitleContainer}>
                <TextField
                  type="text"
                  name="title"
                  label="Subtitle Title"
                  value={sub.title}
                  onChange={(e) => onSubtitleChange(e, index)}
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                      '&:hover fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                        boxShadow: 'none',
                      },
                    },
                    marginBottom: '1.25rem'
                  }}
                  InputProps={{
                    style: {
                      boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                    },
                  }}
                  InputLabelProps={{
                    sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                    required: false
                  }}
                />
                <div className={styles.subDescriptionContainer}>
                  <TextEditor
                    key={index}
                    index={index}
                    propsContent={sub.description}
                    propPlaceholder={"Subtitle Description..."}
                    onChangeContent={onSubDescriptionChange}
                  />
                </div>
                <Button
                  className="actionMobileBtn"
                  onClick={() => { onDeleteSubtitle(index) }}
                  style={{
                    backgroundColor: "#FFD6D2",
                    color: "black",
                  }}
                >
                  Delete
                </Button>
              </div>
            ))}

            <Button
              className="actionMobileBtn"
              style={{
                width: '6rem',
                height: '1.75rem',
                color: "#ffffff",
                backgroundColor: "#37446F",
                boxShadow: "3.13px 3.13px 6.25px 0px #59596F80, -2.08px -2.08px 6.25px 0px #FFFFFF ",
                alignSelf: "flex-start" // Add this line to align the button to the left
              }}
              onClick={() => {
                addSubtitle();
              }}
            >
              Add Subtitle
            </Button>

          </div>
          <div className={styles.lowerContainer} >
            <Button
              className="formBtnMobile"
              style={{
                color: "#000000",
                border: "2.03px solid #313C63",
                fontSize: "0.75rem",
                backgroundColor: "#ffffff",
                boxShadow: "3.13px 3.13px 6.25px 0px #59596F80, -2.08px -2.08px 6.25px 0px #FFFFFF ",
                flex: '1',
              }}
              onClick={() => {
                handleCloseAddDialog();
                setBlogsDetails({
                  title: "",
                  order: "",
                  author: "",
                  imageURL: "",
                  description: "",
                  shortDescription: "",
                  summary: "",
                })
              }}
            >
              Cancel
            </Button>
            <Button
              className="formBtnMobile"
              style={{
                color: "#ffffff",
                fontSize: "0.75rem",
                backgroundColor: "#37446F",
                boxShadow: "3.13px 3.13px 6.25px 0px #59596F80, -2.08px -2.08px 6.25px 0px #FFFFFF ",
                margin: '0',
                flex: '1',
              }}
              onClick={() => {
                isOpen.add ? addBlog() : updateBlog()
              }}
            >
              {isOpen.add ? "Add" : "Update"}
            </Button>
          </div>
        </div>
      </Dialog>
      
      {/* on Successfull Update/Add Dialog Box */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <div style={{ marginBottom: '2rem' }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={(theme) => ({
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div style={{ width: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', padding: '3rem', paddingTop: '0' }}>
          <CheckCircleIcon style={{ color: 'green', fontSize: '2rem', margin: '1rem' }} />
          <h4 style={{textAlign: 'center'}}>{isSuccessfullAddCalled ? "Added" : "Updated"} Successful!</h4>
          <p style={{ textAlign: 'center', fontSize: '0.75rem' }}>Article has been {isSuccessfullAddCalled ? "added" : "updated"} successfully.</p>
        </div>
      </BootstrapDialog>

      {/* Delete Dialog Box */}
      <Dialog
        open={openDeleteDialog}
        onClose={(prev) => !prev}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ textAlign: 'center' }}
      >
        <ErrorOutlineIcon style={{ color: 'red', fontSize: '32px', margin: '12px auto' }} />
        <DialogTitle id="alert-dialog-title" style={{ fontSize: '18px', fontWeight: '500', lineHeight: '0' }}>
          {"Are you Sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ fontSize: '12px' }}>
            You won't be able to revert this!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} style={{ textTransform: 'none', width: '120px', height: '36px', border: '2.03px solid #37446F', borderRadius: '5.85px', background: 'none', color: 'black' }}>Cancel</Button>
          <Button onClick={() => deleteBlog(categorySelectedForDelete)} style={{ textTransform: 'none', width: '120px', height: '36px', borderRadius: '5.85px', background: '#37446F', color: 'white' }} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Link to={'/blogs'}>
        <ArrowCircleLeftIcon sx={{ fill: '#37446f' }} style={{ position: 'fixed', bottom: '15px', right: '15px', cursor: 'pointer', marginTop: '1.25rem' }} />
      </Link>
    </>
  );
};

export default BlogsMobile;
