import styles from './ReportTab.module.css'
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ExperimentReport from '../experimentReport/ExperimentReport'
import SkillReport from '../skill-report/SkillReport'
import WarReport from '../war-report/WarReport';
import BattleReport from '../battle-report/BattleReport'
import ReportTabMobile from './ReportTabMobile';
import isDesktop from '../isDesktop';
import axios from 'axios';
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ReportTab = () => {
    const [value, setValue] = React.useState(0);
    const [users, setUsers] = useState([]);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const token = localStorage.getItem("token");
    const isDesktopDevice = isDesktop();
    const fetchUsers = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/user/all`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
    
          if (response.status === 200) {
            setUsers(response.data.data);
          } else {
            console.error("Error fetching users: ", response.data.message);
          }
        } catch (error) {
          console.error("Error fetching users: ", error);
        }
    };
    useEffect(() => {
        fetchUsers();
    }, []);
    return (
        <>
            {
                isDesktopDevice ? (
                    <div className={styles.pageContainer}>
                        <div style={{ fontSize: '1.795vw', fontWeight: '600', marginBottom: '1.5vw' }}>Report</div>
                        <Box sx={{ width: '100%', padding: '0' }}>
                            <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs example"
                                    className={styles.tabContainer}
                                    TabIndicatorProps={{
                                        hidden: true,
                                    }}
                                >
                                    <Tab label="Skill Report" {...a11yProps(0)} className={styles.tabStyle}
                                        sx={{
                                            '&.Mui-selected': {
                                                outline: 'none',
                                                color: '#000000'
                                            }
                                        }}
                                    />
                                    <Tab label="Experiment Report" {...a11yProps(1)} className={styles.tabStyle}
                                        sx={{
                                            '&.Mui-selected': {
                                                outline: 'none',
                                                color: '#000000'
                                            }
                                        }}
                                    />
                                    <Tab label="War Report" {...a11yProps(2)} className={styles.tabStyle}
                                        sx={{
                                            '&.Mui-selected': {
                                                outline: 'none',
                                                color: '#000000'
                                            }
                                        }}
                                    />
                                    <Tab label="Battle Report" {...a11yProps(3)} className={styles.tabStyle}
                                        sx={{
                                            '&.Mui-selected': {
                                                outline: 'none',
                                                color: '#000000'
                                            }
                                        }}
                                    />
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={value} index={0}>
                                <SkillReport users={users} />
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <ExperimentReport users={users} />
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={2}>
                                <WarReport users={users} />
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={3}>
                                <BattleReport users={users} />
                            </CustomTabPanel>
                        </Box>
                    </div>
                ) : (
                    <ReportTabMobile />
                )
            }
        </>
    )
}

export default ReportTab;