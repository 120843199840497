import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import CardContent from "@mui/material/CardContent";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import styles from "./UserExperimentReportMobile.module.css";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Autocomplete
} from "@mui/material";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { TablePagination } from "@mui/material";
import { useLocalStorage } from "@uidotdev/usehooks";
import { ReactComponent as UserIcon } from '../assets/icons/UserIcon.svg';
import { ReactComponent as Hemberger } from '../assets/icons/Hemberger.svg';
import { ReactComponent as DownloadIcon } from '../assets/downloadIcon.svg';
import Sidebar from "../sidenav/Sidenav";

const UserExperimentReportMobile = () => {
    const [token,setToken] = useLocalStorage('token',null);
    const [experimentUsers, setExperimentUsers] = useState([]);
    const [users,setUsers]=useState([])
    const [experiments, setExperiments] = useState([]);
    const [selectedExperiment, setSelectedExperiment] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [filteredExperimentUsers, setFilteredExperimentUsers] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [filterFromDate, setFilterFromDate] = useState(dayjs("01/01/1995")); // From date
    const [filterToDate, setFilterToDate] = useState(dayjs("")); // To date
    
    useEffect(() => {
        fetchExperiments();
    }, []);

    useEffect(()=>{
        if (experimentUsers.length!=0) {
                const temp=new Set();
                const tempUserArray=experimentUsers.filter((val,index)=>{
                    if (temp.has(val.user_id)) {
                        return false
                    }
                    temp.add(val.user_id)
                    return true
                })
                setUsers(tempUserArray)
        }
    },[experimentUsers]);
    useEffect(() => {
        if (filterFromDate && filterToDate && selectedExperiment) {
            const filteredUsers = users.filter(
                (user) =>
                    user.created_at >= filterFromDate.toISOString() &&
                    user.created_at <= filterToDate.toISOString()
            );
            setFilteredExperimentUsers(filteredUsers);
        }
    }, [filterFromDate, filterToDate]);

    useEffect(() => {
        if (selectedExperiment) {
            fetchExperimentUsers(selectedExperiment.experiment_id);
        }
    }, [selectedExperiment]);

    useEffect(() => {
        if (selectedExperiment && selectedUser) {
            const filteredUsers = experimentUsers.filter(
                user =>
                    user.experiment_id === selectedExperiment.experiment_id &&
                    user.user_id === selectedUser.user_id
            );
            setFilteredExperimentUsers(filteredUsers);
        } else {
            setFilteredExperimentUsers([]);
        }
    }, [experimentUsers, selectedExperiment, selectedUser]);

    const fetchExperiments = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/experiment/all`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.status === 200) {
                setExperiments(response.data.data);
            } else {
                console.error("Error fetching experiments:", response.data.message);
            }
        } catch (error) {
            console.error("Error fetching experiments:", error);
        }
    };

    const fetchExperimentUsers = async (experimentId) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/report/experiment-users/${experimentId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.status === 200) {
                setExperimentUsers(response.data.data);
            } else {
                console.error("Error fetching experiment users:", response.data.message);
            }
        } catch (error) {
            console.error("Error fetching experiment users:", error);
        }
    };

    const handleExperimentChange = (event) => {
        const selectedExp = experiments.find(
            (exp) => exp.experiment_id === event.target.value
        );
        setSelectedExperiment(selectedExp);
        setSelectedUser(null); // Reset selected user when experiment changes
        setPage(0);
    };

    const handleUserChange = (event, value) => {
        setSelectedUser(value);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const formatDate = (utcDateString) => {
        const testDate = new Date(utcDateString);
        testDate.setHours(testDate.getHours() + 5);
        testDate.setMinutes(testDate.getMinutes() + 30);
        const options = {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        };
        return testDate.toLocaleDateString("en-GB", options);
    };
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [logoutDialog, setLogoutDialog] = useState(false);
    const closeSidebar = () => {
      setIsSidebarOpen(false);
    };
    return (
      <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '0.5rem 0.75rem 1.25rem 0.75rem' }}>
        <div onClick={() => setIsSidebarOpen(!isSidebarOpen)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', width: '36px', height: '36px', borderRadius: '18px', background: '#EAF1F7' }}>
          <Hemberger />
        </div>
        <Link to="/profile">
          <UserIcon style={{ cursor: 'pointer' }} />
        </Link>
      </div>
      <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} setLogoutDialog={setLogoutDialog} />
      <Card className="col-md-12 shadow-none" style={{ padding: '1rem', paddingTop: '0' }}>
          <CardContent style={{ padding: '0' }}>
              <div className="filters">
                  <h4>User Experiment Report</h4>
                  <div className={styles.reportHeader}>
                      <div className={styles.fieldContainer}>
                          <FormControl
                              className="mb-3"
                              sx={{
                                  width: '100%', '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      borderColor: 'transparent',
                                    },
                                    '&:hover fieldset': {
                                      borderColor: 'transparent',
                                    },
                                    '&.Mui-focused fieldset': {
                                      borderColor: 'transparent',
                                      width: '100%',
                                      boxShadow: 'none',
                                    },
                                  },
                                }}
                                InputLabelProps={{
                                  sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                                  required: false
                                }}
                                style={{
                                  height: 'fit-content',
                                  boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                                }}
                          >
                              <InputLabel id="experiment-label" style={{ color: "#6c6c6c" }}>Experiment</InputLabel>
                              <Select
                                  labelId="experiment-label"
                                  id="experiment-select"
                                  value={selectedExperiment ? selectedExperiment.experiment_id : ""}
                                  onChange={handleExperimentChange}
                                  label="Experiment"
                              >
                                  {experiments.map((experimentItem) => (
                                      <MenuItem
                                          key={experimentItem.experiment_id}
                                          value={experimentItem.experiment_id}
                                      >
                                          {experimentItem.experiment_name}
                                      </MenuItem>
                                  ))}
                              </Select>
                          </FormControl>
                          <div style={{display: 'flex', gap: '0.4rem', alignItems: 'center'}}>
                              <FormControl
                                  fullWidth
                                  className="mb-3"
                                  sx={{
                                      "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                          borderColor: "transparent",
                                      },
                                      "&:hover fieldset": {
                                          borderColor: "transparent",
                                      },
                                      "&.Mui-focused fieldset": {
                                          borderColor: "transparent",
                                          boxShadow: "none",
                                      },
                                      },
                                      "&.Mui-focused": { color: "#A8A8A8" },
                                  }}
                                  style={{
                                      boxShadow:
                                      "0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A",
                                  }}
                                  >
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                      <DatePicker
                                          format="YYYY-MM-DD"
                                          value={filterFromDate}
                                          label="From"
                                          onChange={(value) => {
                                              setFilterFromDate(dayjs(value));
                                          }}
                                          // renderInput={(params) => (
                                          //     <TextField {...params} error={false} />
                                          // )}
                                      />
                                  </LocalizationProvider>
                              </FormControl>
                              <FormControl
                                  fullWidth
                                  className="mb-3"
                                  sx={{
                                      "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                          borderColor: "transparent",
                                      },
                                      "&:hover fieldset": {
                                          borderColor: "transparent",
                                      },
                                      "&.Mui-focused fieldset": {
                                          borderColor: "transparent",
                                          boxShadow: "none",
                                      },
                                      },
                                      "&.Mui-focused": { color: "#A8A8A8" },
                                  }}
                                  style={{
                                      boxShadow:
                                      "0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A",
                                  }}
                              >
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                      <DatePicker
                                      format="YYYY-MM-DD"
                                      value={dayjs(filterToDate)}
                                      label="To"
                                      onChange={(value) => {
                                          setFilterToDate(dayjs(value)); 
                                          // console.log(dayjs(value).format("DD-MM-YYYY"), " handleDateChange"); // '16-12-2001'
                                      }}
                                      // renderInput={(params) => (
                                      //     <TextField {...params} error={false} />
                                      // )}
                                      />
                                  </LocalizationProvider>
                              </FormControl>
                          </div>
                          <FormControl 
                              className="mb-3"
                              sx={{
                                    width: '100%', '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        borderColor: 'transparent',
                                      },
                                      '&:hover fieldset': {
                                        borderColor: 'transparent',
                                      },
                                      '&.Mui-focused fieldset': {
                                        borderColor: 'transparent',
                                        width: '100%',
                                        boxShadow: 'none',
                                      },
                                    },
                                  }}
                                  InputLabelProps={{
                                    sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                                    required: false
                                  }}
                                  style={{
                                    boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                              }}
                            >
                              <Autocomplete
                                  id="user-autocomplete"
                                  name="user-autocomplete"
                                  options={users}
                                  getOptionLabel={(option) => option.username}
                                  value={selectedUser}
                                  onChange={handleUserChange}
                                  renderInput={(params) => (
                                      <TextField {...params} label="Username" />
                                  )}
                                  disabled={!selectedExperiment}
                              />
                          </FormControl>
                      </div>
                      <div className={styles.buttonContainer}>
                          <div>
                              <Button
                                  className="outlined-button"
                                  onClick={() => {
                                      setSelectedExperiment(null);
                                      setSelectedUser(null);
                                      setFilterFromDate(dayjs("01/01/1995"));
                                      setFilterToDate(dayjs(""));
                                  }}
                              >
                                  Reset
                              </Button>
                          </div>
                      </div>
                  </div>
                  {(selectedUser || filteredExperimentUsers.length > 0) && (
                      <>
                          <TablePagination
                              rowsPerPageOptions={[10, 25, 50]}
                              component="div"
                              count={filteredExperimentUsers.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              sx={{
                                '& .MuiTablePagination-input': {
                                  marginRight: '0.5rem',
                                  marginLeft: '0',
                                }
                              }}
                          />
                          <table className="table" style={{verticalAlign: 'none'}}>
                              <thead style={{background: '#EAF1F7', borderRadius: '4px'}}>
                                  <tr>
                                      {/* <th>S. No.</th> */}
                                      {/* <th>User ID</th> */}
                                      <th style={{textAlign: 'left'}}>Username</th>
                                      <th style={{textAlign: 'right'}}>Score/Date</th>
                                      {/* <th>Date</th> */}
                                      {/* <th>Action</th> */}
                                  </tr>
                              </thead>
                              <tbody>
                                  {filteredExperimentUsers
                                      .slice(
                                          page * rowsPerPage,
                                          page * rowsPerPage + rowsPerPage
                                      )
                                      .map((user, index) => (
                                          <tr key={user.user_id}>
                                              {/* <td>{index + 1}</td> */}
                                              {/* <td>{user.user_id}</td> */}
                                              <td style={{textAlign: 'left', verticalAlign: 'middle'}}>{user.username}</td>
                                              <td style={{textAlign: 'right'}}>
                                                <div style={{display: 'flex', gap: '0.8rem', alignItems: 'center', justifyContent: 'end'}}>
                                                    <div style={{textAlign: 'right'}}>
                                                      <div>
                                                        {user.total_correct}/{user.total_questions}
                                                      </div>
                                                      <div>
                                                        {formatDate(user.created_at)}
                                                      </div>
                                                    </div>
                                                    <Link
                                                      key={user.attempt_id}
                                                      to={`/labs/${user.user_id}/experiments/report/${user.attempt_id}`}
                                                      target="_blank"
                                                  >
                                                      {/* <Button className="filled-sm" style={{backgroundColor:"#D1F4DE", color:"black", textTransform:"capitalize"}}>
                                                          Download
                                                      </Button> */}
                                                      <DownloadIcon />
                                                  </Link>
                                                </div>
                                              </td>
                                              {/* <td>{formatDate(user.created_at)}</td> */}
                                              
                                          </tr>
                                      ))}
                              </tbody>
                          </table>
                      </>
                  )}
              </div>
          </CardContent>
      </Card>
      <Link to={'/report'}>
        <ArrowCircleLeftIcon sx={{ fill: '#37446f' }} style={{ position: 'fixed', bottom: '15px', right: '15px', cursor: 'pointer', marginTop: '1.25rem' }} />
      </Link>
      </>
    );
};

export default UserExperimentReportMobile;
