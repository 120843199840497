import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  OutlinedInput,
  ListItemText,
  Checkbox,
  Button,
  Card,
  CardContent,
  IconButton,
  Menu,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Collapse,
  Autocomplete,
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { tableCellClasses } from "@mui/material/TableCell";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import axios from "axios";
import "./WarReport.css";
import Sidebar from "../sidenav/Sidenav";
import { useLocalStorage } from "@uidotdev/usehooks";
import { ToastContainer, toast } from "react-toastify";

import { ReactComponent as Hemberger } from '../assets/icons/Hemberger.svg';
import { ReactComponent as UserIcon } from '../assets/icons/UserIcon.svg';
import { Link } from "react-router-dom";
import styles from './WarReportMobile.module.css';

function formatDate(date) {
  date = new Date(date);
  let ISTDate = new Date(date.getTime());
  // let day = ISTDate.getDate();
  // let month = ISTDate.getMonth() + 1;
  // let year = ISTDate.getFullYear();
  // day = day < 10 ? '0' + day : day;
  // month = month < 10 ? '0' + month : month;
  // let formattedDate = `${day}.${month}.${year}`;

  let hours = ISTDate.getHours();
  let minutes = ISTDate.getMinutes();
  let AmOrPm = hours >= 12 ? 'pm' : 'am';
  hours = (hours % 12) || 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  let formattedTime = `${hours}:${minutes}${AmOrPm}`;
  return formattedTime;
}
/* MUI Table */
function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component="th" scope="row">
          <div>{row.name}</div>
          <div>{row.user_class}</div>
        </TableCell>
        <TableCell align="right">
          <div>{formatDate(row.start_time)}</div>
          <div>{formatDate(row.end_time)}</div>
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            style={{ color: 'black'}}
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow sx={{ "& td": { border: 0, height: open ? 'auto' : 0 }, borderBottom: '1px solid black' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit  >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '10px', marginTop: '6px' }}>
              <div>Total Score: {row.total_questions}</div>
              <div>Earned Score: {row.total_correct}</div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const WarReportMobile = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [logoutDialog, setLogoutDialog] = useState(false);
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };
  const [skills, setSkills] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [token, setToken] = useLocalStorage('token', null);
  const [filter, setFilter] = useState("");
  const [users, setUsers] = useState([]);
  const [wars, setWars] = useState([]);
  const [selectedWar, setSelectedWar] = useState([]);
  const [reports, setReports] = useState([]);
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(11);

  const handleInputChange = (e, value) => {
    setFilter(value?.username);
  };
  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/user/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setUsers(response.data.data);
      } else {
        console.error("Error fetching users: ", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching users: ", error);
    }
  };
  const fetchSkills = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/skills/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setSkills(response.data.data);
      } else {
        console.error("Error fetching skills: ", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching skills: ", error);
    }
  };

  const fetchWars = async (skillId) => {
    console.log(skillId, " got the skill id");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/war/all/${skillId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setWars(response.data.data);
        console.log(response.data.data, " got the wars");
      } else {
        console.error("Error fetching wars:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching wars:", error);
    }
  };

  const fetchReports = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/report/war-report`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setReports(response.data.data);
      } else {
        console.error("Error fetching reports:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching reports:", error);
    }
  };

  const handleSkillChange = (event) => {
    const selectedSkillId = event.target.value;
    setSelectedSkill(selectedSkillId);
    fetchWars(selectedSkillId);
  };

  const handleExportClick = async () => {
    if(selectedSkill.length === 0){
      toast.error("No skill selected");
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/report/export-war`,
        {
          name: filter,
          war_id: selectedWar,
          skill_id: selectedSkill,
        },
        {
          responseType: "blob",
        }
      );
      // if(response.data) fetchReports();
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "WarAttempts.xlsx");
      document.body.appendChild(link);
      link.click();
      console.log("File exported successfully");
    } catch (error) {
      console.error("Error exporting file:", error);
      if (error.response.status==400) {
        toast.error("No Data Available");
      }
    }
  };

  useEffect(() => {
    fetchSkills();
    fetchUsers();
    // fetchReports();
  }, []);

  const handleReset = () => {
    setReports([]);
    setFilter("");
    setSelectedSkill("");
    setSelectedWar("");
  };

  if(!isAuthorized) {
    return (
      <div className="container-fluid mt-5">
        <h2>You are unauthorized to access this page</h2>
      </div>
    )
  }

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '0.5rem 0.75rem 1.25rem 0.75rem' }}>
        <div onClick={() => setIsSidebarOpen(!isSidebarOpen)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', width: '36px', height: '36px', borderRadius: '18px', background: '#EAF1F7' }}>
          <Hemberger />
        </div>
        <Link to={'/profile'}>
          <UserIcon />
        </Link>
      </div>
      <ToastContainer />
      <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} setLogoutDialog={setLogoutDialog} />
      <h4 style={{ margin: '0.938rem' }}>War Report</h4>
        <div className="align-center-all full-height">
          <Card style={{ boxShadow: 'none', padding: '0' }}>
            <CardContent style={{ padding: '0' }}>
              <div className="filters" style={{ marginBottom: '3.75rem' }}>
                <div>
                  <div className="col-md-3 mt-1">

                    <FormControl className="mt-3" fullWidth sx={{
                      width: '100%', '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                          width: '100%',
                          boxShadow: 'none',
                        },
                      },
                    }}
                      style={{
                        boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                      }}
                    >
                      <InputLabel id="experiment-label" style={{ color: "#6c6c6c" }}>
                        Skills
                      </InputLabel>
                      <Select
                        labelId="skill-label"
                        id="skill-select"
                        // multiple
                        value={selectedSkill}
                        onChange={handleSkillChange}
                        label="Skills"
                      >
                        {skills.map((skill) => (
                          <MenuItem key={skill.skill_id} value={skill.skill_id}>
                            {/* <ListItemText primary={skill.skill_name} /> */}
                            {skill.skill_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>


                    <FormControl className="mt-3" fullWidth sx={{
                      width: '100%', '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                          width: '100%',
                          boxShadow: 'none',
                        },
                      },
                    }}
                      style={{
                        boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                      }}
                    >
                      <InputLabel id="experiment-label" style={{ color: "#6c6c6c" }}>
                        War
                      </InputLabel>
                      <Select
                        labelId="war-label"
                        id="war-select"
                        // multiple
                        value={selectedWar}
                        onChange={(e) => setSelectedWar(e.target.value)}
                        label="Wars"
                      >
                        {wars.length == 0 && <MenuItem value="">None</MenuItem>}
                        {wars.map((war) => (
                          <MenuItem key={war.war_id} value={war.war_id}>
                            {/* <ListItemText primary={war.war_name} /> */}
                            {war.war_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/* <TextField
                      className="mt-3"
                      label="Date"
                      type="date"
                      value={''}
                      onChange={() => { }}
                      InputProps={{ style: { height: 'auto' } }}
                      sx={{
                        width: '100%', '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'transparent',
                          },
                          '&:hover fieldset': {
                            borderColor: 'transparent',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'transparent',
                            width: '100%',
                            boxShadow: 'none',
                          },
                        },
                      }}
                      InputLabelProps={{
                        sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                        required: false,
                        shrink: true
                      }}
                      style={{
                        boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                      }}
                    /> */}
                    <Autocomplete
                      id="userId"
                      options={users}
                      getOptionLabel={(option) => option.username}
                      value={filter ? users.find((user) => user.username === filter) : null}
                      onChange={handleInputChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="mt-3"
                          // label="Name"
                          variant="outlined"
                          // value={filter}
                          fullWidth
                          // onChange={handleInputChange}
                          placeholder="Username"
                          sx={{
                            width: '100%', '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'transparent',
                              },
                              '&:hover fieldset': {
                                borderColor: 'transparent',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'transparent',
                                width: '100%',
                                boxShadow: 'none',
                              },
                            },
                            '& .MuiInputBase-input': {
                              padding: '0.75rem !important',
                              paddingLeft: '0.3rem !important',
                            },
                          }}
                          style={{
                            boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                          }}
                        />
                      )}
                    />
                    <div className="mt-3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Button
                        fullWidth
                        color="error"
                        style={{ flex: 1 }}
                        variant="contained"
                        className="mr-1 button-two cancelMobileButtonStyles"
                        onClick={handleReset}
                      >
                        Reset
                      </Button>
                      <Button
                        fullWidth
                        style={{ flex: 1 }}
                        variant="contained"
                        color="primary"
                        onClick={handleExportClick}
                        className="button-two addMobileButtonSytles"
                      >
                        Export
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              {/* MUI Table */}
              {
                reports.length > 0 && (
                  <Table aria-label="collapsible table" sx={{
                    [`& .${tableCellClasses.root}`]: {
                      borderBottom: "none"
                    }
                  }}>
                    <TableHead style={{ height: '2.75rem', borderRadius: '4px !important', background: 'rgba(234, 241, 247, 1)', boxShadow: '2px 2px 4px 0px rgba(174, 174, 192, 0.5)' }}>
                      <TableRow>
                        <TableCell>Name & Class</TableCell>
                        <TableCell align="right">Start Time/End Time</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reports
                        ?.filter(
                          (report) =>
                            (!filter ||
                              report.name
                                ?.toLowerCase()
                                .includes(filter?.toLowerCase()) ||
                              filter === "") &&
                            (!selectedWar ||
                              report.war_id?.toString().includes(selectedWar) ||
                              selectedWar === "") &&
                            (!selectedSkill ||
                              report.skill_id
                                ?.toString()
                                .includes(selectedSkill) ||
                              selectedSkill === "")
                        )
                        ?.map((filteredReport) => (
                          <Row key={filteredReport.attempt_id} row={filteredReport} />
                        ))}
                    </TableBody>
                  </Table>
                )
              }
            </CardContent>
          </Card>
        </div>
      <Link to={'/report'}>
        <ArrowCircleLeftIcon sx={{ fill: '#37446f' }} style={{ position: 'fixed', bottom: '15px', right: '15px', cursor: 'pointer', marginTop: '1.25rem' }} />
      </Link>
    </>
  );
};

export default WarReportMobile;
