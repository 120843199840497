import React, { useEffect, useState } from 'react';
import { Button, Card } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import navLinks from '../navLinks/Navlinks'; // Ensure this path is correct
import './Dashboard.css';
import isDesktop from '../isDesktop';
import Sidebar from '../sidenav/Sidenav';
import { ReactComponent as UserIcon } from '../assets/icons/UserIcon.svg';
import { ReactComponent as Hemberger } from '../assets/icons/Hemberger.svg';
import { registerServiceWorker } from '../war-report/serviceWorkerUtils';
const publicVapidKey = process.env.REACT_APP_PUBLIC_VAPID_KEY;
const Dashboard = () => {
  const navigate = useNavigate();

  const handleNavigation = (route) => {
    navigate(route);
  };

  const adminSection = navLinks.find(link => link.tag === "Admin");
  const adminTabs = adminSection ? adminSection.tabs : [];

  const leadsSection = navLinks.find(link => link.tag === "Lead Management System");
  const leadTabs = leadsSection ? leadsSection.tabs : [];

  const ContentSection = navLinks.find(link => link.tag === "Content Management System");
  const contentTabs = ContentSection ? ContentSection.tabs : [];
  const isDesktopDevice = isDesktop();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [logoutDialog, setLogoutDialog] = useState(false);
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  useEffect(()=>{
    registerServiceWorker
    (
      () => {},
      () => {},
      publicVapidKey
    )
  },[])
  return (
    <>
      {!isDesktopDevice && (
        <>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '0.5rem 0.75rem 1.25rem 0.75rem' }}>
            <div onClick={() => setIsSidebarOpen(!isSidebarOpen)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', width: '36px', height: '36px', borderRadius: '18px', background: '#EAF1F7' }}>
              <Hemberger />
            </div>
            <Link to='/profile'>
              <UserIcon />
            </Link>
          </div>
          <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} setLogoutDialog={setLogoutDialog} />
        </>
      )}
      <div className="container-fluid" style={{marginTop: isDesktopDevice && '6rem'}}>
        <div className="content-wrapper">
          <div className="d-flex">
            <div className="page-title">Dashboard</div>
          </div>
          <div className='subtitle'>Admin</div>
          <div className='dashboard-card-container'>
            {adminTabs.map((tab) => (
              <div key={tab.id}>
                <div onClick={() => handleNavigation(tab.route)} className='dashboard-card'>
                  <div className='card-title'>
                    {tab.title}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className='subtitle'>Lead Management System</div>
          <div className='dashboard-card-container'>
            {leadTabs.map((tab) => (
              <div key={tab.id}>
                <div onClick={() => handleNavigation(tab.route)} className='dashboard-card'>
                  <div className='card-title'>
                    {tab.title}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className='subtitle'>Content Manangement System</div>
          <div className='dashboard-card-container'>
            {contentTabs.map((tab) => (
              <div key={tab.id}>
                <div onClick={() => handleNavigation(tab.route)} className='dashboard-card'>
                  <div className='card-title'>
                    {tab.title}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;