import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./BulkLoginMobile.module.css"
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  TextField,
  InputLabel, Select, ListItemText, MenuItem, Autocomplete,
} from "@mui/material";
import { readFile } from "../utils/utils";
import { useLocalStorage } from "@uidotdev/usehooks";
import Sidebar from "../sidenav/Sidenav";
import { ReactComponent as Hemberger } from '../assets/icons/Hemberger.svg';
import { ReactComponent as UserIcon } from '../assets/icons/UserIcon.svg';
import { Link } from "react-router-dom";

const BulkLoginMobile = () => {
  const [token, setToken] = useLocalStorage('token', null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [updateFile, setUpdateFile] = useState(null);
  const [addDialog, setAddDialog] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [institutes, setInstitutes] = useState([]);
  const [updateDialog, setUpdateDialog] = useState(false);
  const [selectedInstitute, setSelectedInstitute] = useState(null);

  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(14);

  const handleAdd = async () => {
    try {
      const formData = new FormData();
      formData.append("excelFile", selectedFile);

      await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/bulkRegister/add`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Users added successfully");
    } catch (error) {
      console.error("Error adding Users:", error);
      if (error.response && error.response.status === 409) {
        toast.error(`${error.response.data.message}`);
      } else {
        toast.error(`Error adding Users: ${error.message}`);
      }
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setSelectedFileName(file.name);
  };
  const [updateFileName, setUpdateFileName] = useState("");
  const handleUpdateFileChange = (event) => {
    const file = event.target.files[0];
    setUpdateFile(file);
    setUpdateFileName(file.name);
  };

  const fromDateChangeHandler = (value) => {
    const date = value.target.value;
    setFromDate(date);
  };

  const toDateChangeHandler = (value) => {
    const date = value.target.value;
    setToDate(date);
  };

  const getInstituteData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/institute/getAll`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = response.data.data;
    const temp = response.data.data?.map((ele) => {
      return {
        label: `${ele.institute_name} (${ele.institute_referral_id})`,
        institute_id: ele.institute_id,
      };
    });
    setInstitutes(temp);
  };

  useEffect(() => {
    getInstituteData();
  }, []);

  const onDownloadPress = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/bulkRegister/getAll`,
        {
          responseType: "blob",
          params: {
            selectedInstitute: encodeURIComponent(
              JSON.stringify(selectedInstitute)
            ),
            fromDate: encodeURIComponent(JSON.stringify(fromDate)),
            toDate: encodeURIComponent(JSON.stringify(toDate)),
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "BulkUsers.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
      toast.success("File downloaded successfully");
    } catch (error) {
      const { data } = error.response;
      const file = await readFile(data);
      const { message } = JSON.parse(file);
      toast.error(message);
    }
  };


  const handleUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append("excelFile", updateFile);

      await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/bulkRegister/update`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Users added successfully");
    } catch (error) {
      console.error("Error adding Users:", error);
      if (error.response && error.response.status === 409) {
        toast.error(`${error.response.data.message}`);
      } else {
        toast.error(`Error adding Users: ${error.message}`);
      }
    }
  };
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [logoutDialog, setLogoutDialog] = useState(false);
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  if (!isAuthorized) {
    return (
      <div className="container-fluid mt-5">
        <h2 style={{marginTop: '5%'}}>You are unauthorized to access this page</h2>
      </div>
    );
  }

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '0.5rem 0.75rem 1.25rem 0.75rem' }}>
        <div onClick={() => setIsSidebarOpen(!isSidebarOpen)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', width: '36px', height: '36px', borderRadius: '18px', background: '#EAF1F7' }}>
          <Hemberger />
        </div>
        <Link to="/profile">
          <UserIcon />
        </Link>
      </div>
      <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} setLogoutDialog={setLogoutDialog} />
      <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', margin: '1.313rem 0.75rem' }}>
        <h4>Bulk Login</h4>
        <div>
          <Button
            variant="contained"
            onClick={() => {
              setUpdateDialog(true);
            }}
            className="button-two mr-1"
            style={{ width: '6rem', background: '#37446F', height: '1.75rem' }}
          >
            Update
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setAddDialog(true);
            }}
            className="button-two"
            style={{ width: '6rem', background: '#37446F', height: '1.75rem' }}
          >
            Add
          </Button>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: '2.179vw 0.75rem' }}>
        <Autocomplete
          fullWidth
          value={selectedInstitute}
          onChange={(event, newValue) => {
            setSelectedInstitute(newValue);
          }}
          options={institutes}
          isOptionEqualToValue={(option, value) =>
            option.id === value.id || value === null
          }
          renderInput={(params) => (
            <TextField {...params} label="Institute Name"
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent',
                  boxShadow: 'none',
                },
              },
              marginBottom: '1rem'
            }}
            style={{
              boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
            }}
            InputLabelProps={{
              sx: { "&.Mui-focused": { color: "#A8A8A8" } },
              required: false
            }}
            />
          )}
        />
        <div>
          <TextField
            label="From Date"
            type="date"
            // value={fromDate}
            onChange={fromDateChangeHandler}
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            style={{ marginRight: "0.75rem" }}
          />
          <TextField
            label="To Date"
            type="date"
            // value={toDate}
            onChange={toDateChangeHandler}
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            style={{   }}
          />
        </div>
        <div className="mt-3" style={{ display: 'flex', margin: '3.25rem 0.75rem', width: '100%' }}>
          <Button
            variant="contained"
            fullWidth
            onClick={onDownloadPress}
            className="button-two"
            style={{ alignSelf: "center", background: '#37446F', height: '2.25rem', marginTop: '12px' }}
          >
            Download
          </Button>
        </div>
      </div>

      {/* Update Dialog */}
      <Dialog
        open={updateDialog}
        fullWidth
        onClose={() => {
          setUpdateDialog(false);
        }}
        className="d-flex flex-column justify-content-center"

      >
        <DialogTitle>Update User</DialogTitle>
        <DialogContent>
          <label htmlFor="file-upload" className={styles.customFileUpload} >
            Choose File
          </label>
          <Input
            id="file-upload"
            style={{ display: 'none' }}
            type="file"
            fullWidth
            onChange={handleUpdateFileChange}
          />
          <p style={{ margin: '0', padding: '0', textAlign: 'center' }}>{updateFileName ? 'File Chosen' : 'No file chosen'}</p>
          <ToastContainer />
          <Button
            variant="contained"
            onClick={handleUpdate}
            fullWidth
            style={{ marginTop: "1vw", background: '#37446F', height: '2.25rem', textTransform: 'none' }}
          >
            Update Users
          </Button>
        </DialogContent>
      </Dialog>
      
      {/* Add Dialog Box */}
      <Dialog
        open={addDialog}
        fullWidth
        // maxWidth="md"
        onClose={() => {
          setAddDialog(false);
        }}
        className="d-flex flex-column justify-content-center"
      >
        <DialogTitle>Add User</DialogTitle>
        <DialogContent>
          <label htmlFor="file-upload" className={styles.customFileUpload} >
            Choose File
          </label>
          <Input
            id="file-upload"
            style={{ display: 'none' }}
            type="file"
            fullWidth
            onChange={handleFileChange}
          />
          <p style={{ margin: '0', padding: '0', textAlign: 'center' }}>{selectedFileName ? 'File Chosen' : 'No file chosen'}</p>
          <ToastContainer />
          <Button
            variant="contained"
            onClick={handleAdd}
            fullWidth
            style={{ marginTop: "1vw", background: '#37446F', height: '2.25rem', textTransform: 'none' }}
          >
            Add Users
          </Button>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default BulkLoginMobile