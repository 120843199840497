import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Input,
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
  TextField,
  FormControlLabel,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Checkbox,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../authService";
import { useLocalStorage } from "@uidotdev/usehooks";
import styles from "./Login.module.css";
import SmartsaLogo from '../../assets/smartsa-new-icon-mobile.svg';
import { Visibility, VisibilityOff } from "@mui/icons-material";

const theme = createTheme({
  palette: {
    secondary: {
      main: "#37446F"
    },
  },
});


const LoginMobile = ({ }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [rememberMe, setRememberMe] = useState(false);
  const [token, setToken] = useLocalStorage("token", null);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // const token = localStorage.getItem('token');
    if (token) {
      // setIsLoggedIn(true)
      navigate("/dashboard");
    }
  }, [navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, value);


    // console.log(name,value);

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!formData.email || !formData.password){
      setError("Please fill in all fields");
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const { success, data, error } = await login(
        formData.email,
        formData.password
      );

      if (success) {
        console.log("Login successful:", data);
        // setIsLoggedIn(true)
        navigate("/dashboard");
      } else {
        console.error("Login failed:", error);
        setError(error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Card style={{ margin: '0.5rem 0.75rem 1.25rem 0.75rem', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', boxShadow: 'none' }}>
        <CardContent>
          <Typography
            variant="h5"
            align="center"
            className={styles.stylesContainer}
            gutterBottom
            style={{
              marginBottom: "2rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={SmartsaLogo} style={{height: '4rem', marginBottom: '0.5rem'}} />
            Admin Login
          </Typography>
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth>
              <TextField
                id="email"
                type="email"
                name="email"
                label="UserName"
                fullWidth
                value={formData.email}
                className={styles.inputs}
                onChange={handleInputChange}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'transparent',
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                      boxShadow: 'none',
                    },
                  },
                }}
                InputLabelProps={{
                  sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                  required: false
                }}
                InputProps={{
                  style: {
                    boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                  },
                }}
              />
            </FormControl>
            <FormControl fullWidth className="mt-3" variant="outlined"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent',
                    boxShadow: 'none',
                  },
                },
                boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
              }}
            >
              <InputLabel htmlFor="outlined-adornment-password" sx={{ "&.Mui-focused": { color: "#A8A8A8" } }}>
                Password
              </InputLabel>
              <OutlinedInput
                fullWidth={true}
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                sx={{ backgroundColor: "transparent" }}
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      style={{ background: "none" }}
                      onClick={() => {
                        setShowPassword((prev) => !prev);
                      }}
                      onMouseDown={(e) => {
                        e.preventDefault();
                      }}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <FormControl fullWidth className="mt-3" style={{ marginBottom: '1.188rem' }}>
              <FormControlLabel
                sx={{
                  height: "0.75rem",
                  margin: "0",
                  fontSize: "0.625rem",
                  ".MuiFormControlLabel-label": {
                    fontSize: "0.625rem",
                  },
                  verticalAlign: 'middle',
                }}
                control={
                  <Checkbox
                    value={rememberMe}
                    onChange={() => {
                      setRememberMe((prev) => !prev);
                    }}
                    sx={{ height: "0.75rem", width: "0.75rem", marginRight: "0.25rem" }}
                  />
                }
                label="Remember Me"
              />
            </FormControl>
            <CardActions style={{ width: "100%", padding: "0" }}>
              <Button
                type="submit"
                variant="contained"
                className={styles.button}
                color="secondary"

                disabled={!formData.email || !formData.password || loading}
                fullWidth
                style={{ marginTop: "0.5rem", textTransform: "capitalize" }}
              >
                {loading ? "Logging in..." : "Login"}
              </Button>
            </CardActions>
            {error && (
              <Typography variant="body2" color="error" align="center">
                {error}
              </Typography>
            )}
          </form>
        </CardContent>
      </Card>
    </ThemeProvider>
  );
};

export default LoginMobile;
