import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Collapse,
  Switch,
  TextField,
  Typography,
  Table,
  TableHead,
  TableBody,
  TablePagination,
  TableRow,
  TableCell,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchIcon from "@mui/icons-material/Search";
import { useLocalStorage } from "@uidotdev/usehooks";
import { ReactComponent as Hemberger } from "../assets/icons/Hemberger.svg";
import { ReactComponent as UserIcon } from "../assets/icons/UserIcon.svg";
import Sidebar from "../sidenav/Sidenav";
import { Link } from "react-router-dom";

const RegisterCampMobile = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [token, setToken] = useLocalStorage('token', null);
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(24);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [updateDialog, setUpdateDialog] = useState(false);
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const displayedUsers = filteredUsers.slice(startIndex, endIndex);
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredUsers.length - page * rowsPerPage);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [logoutDialog, setLogoutDialog] = useState(false);
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/contact-us/get/all/register`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log(response.data.data, " got all users");
        // const reversedUsers = response.data.data.reverse(); // Reverse the array of users
        const actualUsers = response.data.data;
        const sortedUsers = [...actualUsers].sort((a, b) => {
          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);
          return dateB - dateA;
        });
        setUsers(sortedUsers);
        setFilteredUsers(sortedUsers);
      } else {
        console.error("Error fetching users: ", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching users: ", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [token]);

  // Function to filter users based on search query
  const filterUsers = (query) => {
    if (!query) {
      setFilteredUsers(users);
    } else {
      const filtered = users.filter(
        (user) =>
          user.name.toLowerCase().includes(query.toLowerCase()) ||
          user.email.toLowerCase().includes(query.toLowerCase()) ||
          user.class.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredUsers(filtered);
    }
  };

  useEffect(() => {
    filterUsers(searchQuery);
  }, [searchQuery]);

  function formatDate(dateTimeString) {
    let date = new Date(dateTimeString);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let formattedDate = `${day}.${month}.${year}`;
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let AmOrPm = hours >= 12 ? 'pm' : 'am';
    hours = (hours % 12) || 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let formattedTime = `${hours}:${minutes}${AmOrPm}`;
    return { time: formattedTime, date: formattedDate };
  }

  /* MUI Table */
  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    if(!isAuthorized) {
      return (
        <div className="container-fluid mt-5">
          <h2 style={{marginTop: '2rem'}}>You are unauthorized to access this page</h2>
        </div>
      )
    }
  
    return (
      <React.Fragment>
        <TableRow>
          <TableCell style={{maxWidth: '20ch', wordWrap:'break-word'}}>
            <div>{row?.name}</div>
          </TableCell>
          <TableCell align="right">
            <div>{formatDate(row.created_at).date}</div>
            <div>{formatDate(row?.created_at).time}</div>
          </TableCell>
          <TableCell>
            <IconButton
              aria-label="expand row"
              style={{ color: 'black' }}
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow sx={{ "& td": { border: 0, height: open ? 'auto' : 0 }, borderBottom: '1px solid black', }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderTop: 'none' }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit  >
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 150px', rowGap: '6px', fontSize: '10px', marginTop: '4px', marginBottom: '4px' }}>
                <div>Email: {row?.email}</div>
                <div>Mobile: {row?.phone}</div>
                <div>Class: {row?.class}</div>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  if (!isAuthorized) {
    return (
        <div className="container-fluid mt-5">
            <h2>You are unauthorized to access this page</h2>
        </div>
    )
}

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '0.5rem 0.75rem 1.25rem 0.75rem' }}>
        <div onClick={() => setIsSidebarOpen(!isSidebarOpen)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', width: '36px', height: '36px', borderRadius: '18px', background: '#EAF1F7' }}>
          <Hemberger />
        </div>
        <Link to="/profile">
          <UserIcon />
        </Link>
      </div>
      <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} setLogoutDialog={setLogoutDialog} />

      <Card style={{ boxShadow: 'none', padding: '0' }}>
        <CardContent style={{ padding: '0' }}>
          <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', margin: '0rem 0.75rem 1.5rem 0.75rem' }}>
            <h4>Registration</h4>
            <TextField
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent',
                    boxShadow: 'none',
                  },
                },
              }}
              InputProps={{
                style: {
                  height: '1.75rem',
                  width: '9.75rem',
                  boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A',
                  padding: '0',
                },
                endAdornment: (
                  // <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                  // </InputAdornment>
                ),
              }}
            />
          </div>

          {/* Pagination */}
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              '& .MuiTablePagination-input': {
                marginRight: '0.5rem',
                marginLeft: '0',
              }
            }}
          />
          <ToastContainer />

          {/* MUI Table */}
          <Table aria-label="collapsible table" sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none"
            }
          }}>
            <TableHead style={{ height: '2.564vw', borderRadius: '4px !important', background: 'rgba(234, 241, 247, 1)', boxShadow: '2px 2px 4px 0px rgba(174, 174, 192, 0.5)' }}>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Date & Time</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedUsers
                ?.map((user, ind) => (
                  <Row key={ind} row={user} />
                ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </>
  );
};

export default RegisterCampMobile;
