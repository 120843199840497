import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Input,
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
  TextField,
  makeStyles,
  MenuItem,
  Dialog,
  Modal,
  InputAdornment,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  TableCell,
  DialogTitle,
  DialogActions,
  Collapse,
  DialogContent,
  DialogContentText
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from "@mui/icons-material/Search";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import styles from "./Institute.module.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useLocalStorage } from "@uidotdev/usehooks";
import { ReactComponent as UserIcon } from '../assets/icons/UserIcon.svg';
import { ReactComponent as Hemberger } from '../assets/icons/Hemberger.svg';
import Sidebar from "../sidenav/Sidenav";
import { Link } from "react-router-dom";
import GreenTickDialog from "../Components/MobileDialog/GreenTickDialog";

const instituteList = ["School", "College", "NGO", "Institute"];

const InstituteMobile = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [aState, setAState] = useState("");
  const [pincode, setPincode] = useState("");
  const [token, setToken] = useLocalStorage('token', null);
  const [error, setError] = useState("");
  const [instituteType, setInstituteType] = useState("");
  const [country, setCountry] = useState("");
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [instituteData, setInstituteData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [instId, setInstId] = useState(-1)
  const [isUpdate, setIsUpdate] = useState(false);
  const [isdelete, setIsDelete] = useState(false)
  const [isSuccessfullAddCalled, setIsSuccessfullAddCalled] = useState(false);
  /* on Successfull Update/Add Dialog */
  const [openSuccessMessage, setOpenSuccessMessage] = useState(false);
  const handleClose = () => {
    setOpenSuccessMessage(false);
    setIsSuccessfullAddCalled(false);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const displayedResults = filteredData.slice(startIndex, endIndex);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredData.length - page * rowsPerPage);
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(18);

  const clearFields = () => {
    setName("")
    setEmail("")
    setMobile("")
    setPassword("")
    setCPassword("")
    setAddress("")
    setAState("")
    setCity("")
    setPincode("")
    setInstId(-1)
    setInstituteType("")
    setCountry("")
  }

  const getAllInstitutes = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/institute/getAll`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setInstituteData(response.data.data);
    } catch (error) {
      toast.error("Error occured while fetching institute Data");
      console.error("Error occured while fetching institute Data", error);
    }
  };

  useEffect(() => {
    getAllInstitutes();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (mobile.length !== 10) {
      setError("Invalid Mobile number");
      toast.error("Invalid Mobile number")
      return;
    } else {
      setError("");
    }
    /* console.log(
      name,
      email,
      mobile,
      instituteType,
      password,
      cPassword,
      address,
      city,
      aState,
      pincode,
      " add institute"
    ); */

    if (password !== cPassword) {
      toast.error("Password and Re-Enter Password do not Match");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/institute/add`,
        {
          name: name,
          email: email,
          mobile: mobile,
          institute_type: instituteType,
          password: password,
          address: address,
          city: city,
          state: aState,
          country: country,
          pincode: pincode,
        }
      );
      console.log(response, " got response");
      if (response.status === 200) {
        setOpen(false);
        setOpenSuccessMessage(true);
        setIsSuccessfullAddCalled(true);
        // toast.success("Institute Registered Sucessfull");
        clearFields()
        getAllInstitutes()
      } else {
        toast.error("Error Registering Institute", response.data.message);
      }
    } catch (error) {
      toast.error("Error Registering Institute", error);
    }
  };

  const filterData = (query) => {
    if (query.length === 0) {
      setFilteredData(instituteData);
      return;
    }
    const temp = instituteData.filter(
      (v) =>
        v.institute_name.toLowerCase().includes(query.toLowerCase()) ||
        v.institute_email.toLowerCase().includes(query.toLowerCase()) ||
        v.institute_city.toLowerCase().includes(query.toLowerCase()) ||
        v.institute_type.toLowerCase().includes(query.toLowerCase()) ||
        v.institute_mobile_no.toLowerCase().includes(query.toLowerCase())
    );
    // console.log(temp);
    setFilteredData(temp);
  };

  // console.log(searchQuery);

  useEffect(() => {
    filterData(searchQuery);
  }, [searchQuery, instituteData]);

  const onUpdateClick = (inst) => {
    setIsUpdate(true);
    // console.log(inst);
    setName(inst.institute_name);
    setEmail(inst.institute_email);
    setMobile(inst.institute_mobile_no);
    setInstituteType(inst.institute_type);
    setAddress(inst.institute_address);
    setCountry(inst.country);
    setCity(inst.institute_city);
    setAState(inst.institute_state);
    setPincode(inst.institute_pincode);
    setInstId(inst.institute_id)
    setOpen(true);
  };

  const updateSubmitHandler = async (e) => {
    e.preventDefault();
    if (mobile.length !== 10) {
      setError("Invalid Mobile number");
      toast.error("Invalid Mobile number")
      return;
    } else {
      setError("");
    }
    /* console.log(
      name,
      email,
      mobile,
      instituteType,
      password,
      cPassword,
      address,
      city,
      aState,
      pincode,
      " updated Value"
    ); */

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/institute/update`,
        {
          id: instId,
          name: name,
          email: email,
          mobile: mobile,
          institute_type: instituteType,
          address: address,
          city: city,
          state: aState,
          country: country,
          pincode: pincode,
          password: password
        }
      );
      // console.log(response);
      if (response.status === 200) {
        setOpen(false);
        setOpenSuccessMessage(true);
        // toast.success("Institute Data updated Sucessfully");
        clearFields()
        getAllInstitutes()

      } else {
        toast.error("Error Updating Institute Data", response.data.message);
      }
    } catch (error) {
      toast.error("Error Updating Institute Data", error);
    }

  }

  const onDeleteClick = async (id) => {
    try {
      const result = await axios.delete(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/institute/delete/${id}`);

      if (result.status === 200) {
        toast.success("Institute data deleted sucessfully");
        getAllInstitutes();
        setIsDelete(false)
      }
    } catch (error) {
      console.error(error.response.data.message)
      toast.error("Error deleting institute")
    }
  }

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell component="th" scope="row">
            <div>{row?.institute_name}</div>
            <div>{row?.institute_referral_id}</div>
          </TableCell>
          <TableCell align="right">
            <div>{row?.institute_type}</div>
            <div>{row?.institute_city}</div>
          </TableCell>
          <TableCell>
            <IconButton
              aria-label="expand row"
              style={{ color: 'black' }}
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow sx={{ "& td": { border: 0, height: open ? 'auto' : 0 }, borderBottom: '1px solid black', }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderTop: 'none' }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit  >
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 150px', rowGap: '6px', fontSize: '10px', marginTop: '4px', marginBottom: '4px' }}>
                <div>Email: {row?.institute_email}</div>
                <div>Mobile: {row?.institute_mobile_no}</div>
                <div style={{ display: 'flex', alignItems: 'center' }}>Action: </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '4px' }}>
                  <button className={styles.actionBtn} style={{ backgroundColor: '#CBEBF6' }} onClick={() => onUpdateClick(row)}>Update</button>
                  <button className={styles.actionBtn} style={{ backgroundColor: '#FFD6D2' }} onClick={() => { setIsDelete(true); setInstId(row.institute_id); }}>Delete</button>
                </div>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [logoutDialog, setLogoutDialog] = useState(false);
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  if(!isAuthorized) {
    return (
      <div className="container-fluid mt-5">
        <h2 style={{marginTop: '2rem'}}>You are unauthorized to access this page</h2>
      </div>
    )
  }

  // console.log(page);
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '0.5rem 0.75rem 1.25rem 0.75rem' }}>
        <div onClick={() => setIsSidebarOpen(!isSidebarOpen)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', width: '36px', height: '36px', borderRadius: '18px', background: '#EAF1F7' }}>
          <Hemberger />
        </div>
        <Link to="/profile">
          <UserIcon style={{ cursor: 'pointer' }} />
        </Link>
      </div>
      <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} setLogoutDialog={setLogoutDialog} />
      <Card style={{ boxShadow: 'none', padding: '0' }}>
        <CardContent style={{ padding: '0' }}>
          <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', margin: '0rem 0.25rem', marginBottom: '2.179vw' }}>
            <h4>Institute</h4>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                className="mr-2"
                variant="outlined"
                type="text"
                placeholder="Search..."
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'transparent',
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                      boxShadow: 'none',
                    },
                  },
                }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  style: {
                    height: '1.75rem',
                    width: '9.75rem',
                    paddingRight: '0.2rem',
                    boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                  },
                  endAdornment: <SearchIcon />
                }}
              />
              <Button
                variant="contained"
                className="mr-2 button-two"
                style={{ width: '7.75rem', height: '1.75rem', background: '#37446F', color: 'white', textTransform: 'none' }}
                onClick={() => {
                  setOpen((prev) => !prev);
                  clearFields();
                }}
              >
                Add
              </Button>
            </div>
          </div>

          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, newPage) => {
              setPage(newPage);
            }}
            onRowsPerPageChange={(e) => {
              console.log(e.target.value);
              setRowsPerPage(parseInt(e.target.value, 10));
              setPage(0);
            }}
            sx={{
              '& .MuiTablePagination-input': {
                marginRight: '0.5rem',
                marginLeft: '0',
              }
            }}
          />

          <Table aria-label="collapsible table" sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none"
            }
          }}>
            <TableHead style={{ height: '44px', borderRadius: '4px !important', background: 'rgba(234, 241, 247, 1)', boxShadow: '2px 2px 4px 0px rgba(174, 174, 192, 0.5)' }}>
              <TableRow>
                <TableCell>Name & Institute Referral ID</TableCell>
                <TableCell align="right">Institute type & City</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedResults
                ?.map((inst, ind) => (
                  <Row key={ind} row={inst} />
                ))}
            </TableBody>
          </Table>

        </CardContent>

        <Dialog
          open={open}
          fullWidth
          onClose={() => {
            setOpen(false);
            setIsUpdate(false)
            clearFields()
          }}
        >
          <div className={styles.dialogContainer}>
            <h5 style={{ marginBottom: '2.25rem' }}>
              {!isUpdate ? "Institute Signup" : "Update Institute Details"}
            </h5>
            <form onSubmit={!isUpdate ? handleSubmit : updateSubmitHandler} className={styles.mainForm}>
              <TextField
                fullWidth
                required
                label="Name"
                id="name"
                type="text"
                name="name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'transparent',
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                      boxShadow: 'none',
                    },
                  },
                }}
                InputLabelProps={{
                  sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                  required: false
                }}
                InputProps={{
                  style: {
                    boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                  },
                }}
              />
              {!isUpdate && <TextField
                fullWidth
                required
                label="Email"
                id="email"
                type="email"
                name="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'transparent',
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                      boxShadow: 'none',
                    },
                  },
                }}
                InputLabelProps={{
                  sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                  required: false
                }}
                InputProps={{
                  style: {
                    boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                  },
                }}
              />}
              <TextField
                fullWidth
                required
                label="Mobile no"
                id="mobile"
                type="number"
                error={mobile.length !== 10}
                color={mobile.length !== 10 ? "error" : "primary"}
                helperText={mobile.length !== 10 ? "Mobile number should be 10 digits" : ""}
                name="mobile"
                value={mobile}
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
                sx={{
                  input: {
                    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      "-webkit-appearance": "none",
                      margin: 0,
                    },
                    "&[type=number]": {
                      "-moz-appearance": "textfield",
                    },
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'transparent',
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                      boxShadow: 'none',
                    },
                  },
                }}
                InputLabelProps={{
                  sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                  required: false
                }}
                InputProps={{
                  style: {
                    boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                  },
                }}
              />
              <TextField
                id="insitute-type"
                select
                label="Institute Type"
                defaultValue=""
                value={instituteType}
                required
                onChange={(e) => {
                  setInstituteType(e.target.value);
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'transparent',
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                      boxShadow: 'none',
                    },
                  },
                }}
                InputLabelProps={{
                  sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                  required: false
                }}
                InputProps={{
                  style: {
                    boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                  },
                }}
              >
                {instituteList?.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                required
                // select
                fullWidth
                label="Country"
                id="country"
                type="text"
                name="state"
                value={country}
                onChange={(e) => {
                  setCountry(e.target.value);
                }}
                sx={{
                  input: {
                    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      "-webkit-appearance": "none",
                      margin: 0,
                    },
                    "&[type=number]": {
                      "-moz-appearance": "textfield",
                    },
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'transparent',
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                      boxShadow: 'none',
                    },
                  },
                }}
                InputLabelProps={{
                  sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                  required: false
                }}
                InputProps={{
                  style: {
                    boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                  },
                }}
              >
              </TextField>

              <TextField
                required
                fullWidth
                label="State"
                id="state"
                type="text"
                name="state"
                value={aState}
                onChange={(e) => {
                  setAState(e.target.value);
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'transparent',
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                      boxShadow: 'none',
                    },
                  },
                }}
                InputLabelProps={{
                  sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                  required: false
                }}
                InputProps={{
                  style: {
                    boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                  },
                }}
              />

              <TextField
                required
                fullWidth
                label="City"
                id="city"
                type="text"
                name="city"
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'transparent',
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                      boxShadow: 'none',
                    },
                  },
                }}
                InputLabelProps={{
                  sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                  required: false
                }}
                InputProps={{
                  style: {
                    boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                  },
                }}
              />

              <TextField
                required
                fullWidth
                label="Pincode"
                id="pincode"
                type="number"
                name="pincode"
                value={pincode}
                onChange={(e) => {
                  setPincode(e.target.value);
                }}
                sx={{
                  input: {
                    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      "-webkit-appearance": "none",
                      margin: 0,
                    },
                    "&[type=number]": {
                      "-moz-appearance": "textfield",
                    },
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'transparent',
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                      boxShadow: 'none',
                    },
                  },
                }}
                InputLabelProps={{
                  sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                  required: false
                }}
                InputProps={{
                  style: {
                    boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                  },
                }}
              />

              <TextField
                fullWidth
                label="Address"
                id="address"
                type="text"
                name="address"
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'transparent',
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                      boxShadow: 'none',
                    },
                  },
                }}
                InputLabelProps={{
                  sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                  required: false
                }}
                InputProps={{
                  style: {
                    boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                  },
                }}
              />

              {!isUpdate && (
              <TextField
                fullWidth
                required
                label="Enter Password"
                id="password"
                type="password"
                name="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'transparent',
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                      boxShadow: 'none',
                    },
                  },
                }}
                InputLabelProps={{
                  sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                  required: false
                }}
                InputProps={{
                  style: {
                    boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                  },
                }}
              />
               )}
              {!isUpdate && (
              <TextField
                fullWidth
                required
                label="Re-Enter Password"
                id="cpassword"
                type="password"
                name="cpassword"
                value={cPassword}
                onChange={(e) => {
                  setCPassword(e.target.value);
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'transparent',
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                      boxShadow: 'none',
                    },
                  },
                }}
                InputLabelProps={{
                  sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                  required: false
                }}
                InputProps={{
                  style: {
                    boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                  },
                }}
              />
               )}

              <CardActions className={styles.buttonContainer}>
                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setOpen(false);
                    setIsUpdate(false);
                    clearFields();
                  }}
                  style={{ width: '11.75rem', height: '2.25rem', background: 'white', color: 'black', border: '2.03px solid #37446F', textTransform: 'none' }}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary" style={{ width: '11.75rem', height: '2.25rem', background: '#37446F', color: 'white', textTransform: 'none' }}>
                  {isUpdate ? "Update" : "Register"}
                </Button>
              </CardActions>
            </form>
          </div>
        </Dialog>
        {/* <Dialog open={isdelete} onClose={() => { setIsDelete(false) }} >
          <DialogTitle>Are you sure you want to delete this institute ?</DialogTitle>
          <DialogActions>
            <Button variant="contained" color="error" onClick={() => onDeleteClick(instId)} >Yes</Button>
            <Button variant="contained" color="primary" onClick={() => { setIsDelete(false) }} >No</Button>
          </DialogActions>
        </Dialog> */}

        {/* on Successfull Update/Add Dialog Box */}
        <GreenTickDialog
          subject={"Account"}
          openSuccessMessage={openSuccessMessage}
          handleClose={handleClose}
          massage1={isSuccessfullAddCalled ? "Registration" : "Updated"}
          massage2={isSuccessfullAddCalled ? "registered" : "updated"}
        />
        <Dialog
          open={isdelete}
          onClose={(prev) => !prev}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ textAlign: 'center' }}
        >
          <ErrorOutlineIcon style={{ color: 'red', fontSize: '32px', margin: '12px auto' }} />
          <DialogTitle id="alert-dialog-title" style={{ fontSize: '18px', fontWeight: '500', lineHeight: '0' }}>
            {"Are you sure?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{ fontSize: '12px' }}>
              You won't be able to revert this!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDelete(false)} style={{ textTransform: 'none', width: '7.5rem', height: '2.25rem', border: '2.03px solid #37446F', borderRadius: '0.366rem', background: 'none', color: 'black' }}>
              Cancel
            </Button>
            <Button onClick={() => onDeleteClick(instId)} style={{ textTransform: 'none', width: '7.5rem', height: '2.25rem', borderRadius: '0.366rem', background: '#37446F', color: 'white' }} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

      </Card >
    </>
  );
};

export default InstituteMobile;
