import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  Button,
  Card,
  CardContent,
  TextField,
  TablePagination,
  Autocomplete,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Collapse,
} from '@mui/material';
import { tableCellClasses } from "@mui/material/TableCell";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import axios from 'axios';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { toast, ToastContainer } from 'react-toastify';
import { useLocalStorage } from '@uidotdev/usehooks';
import { ReactComponent as Hemberger } from '../assets/icons/Hemberger.svg';
import { ReactComponent as UserIcon } from '../assets/icons/UserIcon.svg';
import Sidebar from '../sidenav/Sidenav';
import { Link } from 'react-router-dom';

function formatDateAndTime(date) {
  date = new Date(date);
  // Add 5 hours and 30 minutes to convert UTC to IST
  // let ISTOffset = 5.5 * 60 * 60 * 1000; // in ms
  let ISTDate = new Date(date.getTime());

  // Format the date in DD.MM.YYYY
  let day = ISTDate.getDate();
  let month = ISTDate.getMonth() + 1; // Months are zero-based
  let year = ISTDate.getFullYear();

  // Add leading zero to day and month if needed
  day = day < 10 ? '0' + day : day;
  month = month < 10 ? '0' + month : month;
  let formattedDate = `${day}.${month}.${year}`;

  // Format the time in HH.MM am/pm
  let hours = ISTDate.getHours();
  let minutes = ISTDate.getMinutes();
  let AmOrPm = hours >= 12 ? 'pm' : 'am';
  hours = (hours % 12) || 12; // Convert to 12-hour format
  minutes = minutes < 10 ? '0' + minutes : minutes; // Add leading zero to minutes if needed
  let formattedTime = `${hours}:${minutes}${AmOrPm}`;
  return { time: formattedTime, date: formattedDate }; // return adjusted time or original string
}
/* MUI Table */
function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component="th" scope="row">
          <div>{row?.name}</div>
          <div>{row?.username}</div>
        </TableCell>
        <TableCell align="right">
          <div>{formatDateAndTime(row?.created_at).date}</div>
          <div>{formatDateAndTime(row?.created_at).time}</div>
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            style={{ color: 'black' }}
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow sx={{ "& td": { border: 0, height: open ? 'auto' : 0 }, borderBottom: '1px solid black' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderTop: 'none' }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit  >
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 150px', rowGap: '6px', fontSize: '10px', marginBottom: '4px' }}>
              <div>Order ID: {row?.order_id}</div>
              <div>Pay ID: {row?.payment_id}</div>
              <div>Skill: {row?.skill_names}</div>
              <div>Amount: {row?.transaction_amount}</div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


const FinanceTabMobile = () => {
  const [token, setToken] = useLocalStorage('token', null);
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(15);
  const [searchQuery, setSearchQuery] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [allSkills, setAllSkills] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };
  const handleChangeUser = (event, value, name) => {
    console.log(event, value, name, " got username");
    setFormValues((prevState) => ({
      ...prevState,
      [event.target.name || name]: value?.username,
    }));
  }
  const handleChange = (event, value, name) => {
    let newValue;
    if (value) {
      newValue = value.map((item) => item.skill_id);
    } else {
      newValue = [];
    }
    setFormValues((prevState) => ({
      ...prevState,
      [event.target.name || name]: newValue,
    }));
  };


  const [formValues, setFormValues] = useState({
    username: '',
    skills: '',
    order_id: '',
    payment_id: '',
    transaction_amount: '',
    discount: '',
  });

  const handleCloseDialog = () => {
    setFormValues({
      username: '',
      skills: '',
      order_id: '',
      payment_id: '',
      transaction_amount: '',
      discount: '',
    })
    setOpenDialog(false);
  };

  const fetchSkills = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/skills/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setAllSkills(response.data.data);
      } else {
        console.error("Error fetching skills: ", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching skills: ", error);
    }
  };

  const fetchTransactions = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/finance/all`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setTransactions(response.data.data);
        filterTransactions(searchQuery, response.data.data);
      } else {
        console.error('Error fetching transactions: ', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching transactions: ', error);
    }
  };
  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/user/all`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setAllUsers(response.data.data);
      } else {
        console.error('Error fetching users: ', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching users: ', error);
    }
  }
  const filterTransactions = (query, data) => {
    const filtered = data.filter(transaction =>
      transaction?.name.toLowerCase().includes(query.toLowerCase()) ||
      transaction?.username.toLowerCase().includes(query.toLowerCase()) ||
      transaction?.skill_names?.toLowerCase().includes(query.toLowerCase()) ||
      transaction?.order_id.toLowerCase().includes(query.toLowerCase()) ||
      transaction?.payment_id.toLowerCase().includes(query.toLowerCase()) ||
      transaction?.transaction_amount.toString().includes(query)
    );
    setFilteredTransactions(filtered);
  };

  useEffect(() => {
    fetchTransactions();
    fetchSkills()
    fetchUsers();
  }, []);

  useEffect(() => {
    filterTransactions(searchQuery, transactions);
  }, [searchQuery, transactions]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true)
  }

  const handleAddNewTransaction = async () => {
    const { username, skills, order_id, payment_id, transaction_amount, discount } = formValues;

    if (!username || !skills || !order_id || !payment_id || !transaction_amount || !discount) {
      toast.error('All fields are required');
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/finance/add`,
        {
          username,
          skills,
          order_id,
          payment_id,
          transaction_amount,
          discount
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success('Transaction added successfully');
        fetchTransactions();
        handleCloseDialog();
      } else {
        toast.error('Username not found: ', response.data.message);
      }
    } catch (error) {
      toast.error('Username not found: ', error);
    }
  };

  /* Pagination | MUI */
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  }
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  }
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [logoutDialog, setLogoutDialog] = useState(false);
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '0.5rem 0.75rem 1.25rem 0.75rem' }}>
        <div onClick={() => setIsSidebarOpen(!isSidebarOpen)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', width: '36px', height: '36px', borderRadius: '18px', background: '#EAF1F7' }}>
          <Hemberger />
        </div>
        <Link to="/profile">
          <UserIcon />
        </Link>
      </div>
      <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} setLogoutDialog={setLogoutDialog} />
      <Card style={{ boxShadow: 'none', padding: '0' }}>
        <CardContent style={{ padding: '0' }}>
          <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', margin: '0.5rem 0.25rem', marginBottom: '2.179vw' }}>
            <h4>Finance</h4>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem', alignItems: 'center' }}>
              <TextField
                variant="outlined"
                placeholder="Search..."
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'transparent',
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                      boxShadow: 'none',
                    },
                  },
                }}
                InputProps={{
                  style: {
                    height: '1.75rem',
                    width: '8.75rem',
                    padding: '0.2rem',
                    boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                  },
                  endAdornment: <SearchIcon />,
                }}
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <Button variant="contained" style={{ width: '7.75rem', height: '1.75rem', background: '#37446F', color: 'white', textTransform: 'none' }} onClick={handleOpenDialog}>
                Add
              </Button>
            </div>
          </div>
          <TablePagination
            component="div"
            rowsPerPageOptions={[10, 20, 30]}
            count={filteredTransactions.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              '& .MuiTablePagination-input': {
                marginRight: '0.5rem',
                marginLeft: '0',
              }
            }}
          />

          <Table aria-label="collapsible table" sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none"
            }
          }}>
            <TableHead style={{ height: '0.028rem', borderRadius: '4px !important', background: 'rgba(234, 241, 247, 1)', boxShadow: '2px 2px 4px 0px rgba(174, 174, 192, 0.5)' }}>
              <TableRow>
                <TableCell>Name & Username</TableCell>
                <TableCell align="right">Date & Time</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTransactions?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((transaction) => (
                  <Row key={transaction.transaction_id} row={transaction} />
                ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle style={{ fontWeight: '600', paddingBottom: '0' }}>Add Transaction</DialogTitle>
        <form style={{ padding: '18px 24px' }}>
          <Autocomplete
            id="users"
            name="name"
            options={allUsers}
            getOptionLabel={(option) => option.username}
            onChange={(event, value) => handleChangeUser(event, value, "username")}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                margin="normal"
                label="Username"
                fullWidth
                required
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'transparent',
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                      boxShadow: 'none',
                    },
                  },
                  boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A',
                  borderRadius: '4px',
                  marginBottom: '1vw',
                  marginTop: '1.7vw'
                }}
                InputLabelProps={{
                  sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                  required: false
                }}
              />
            )}
          />
          <Autocomplete
            className='mb-2'
            multiple
            id="skills"
            name="skills"
            options={allSkills}
            disableCloseOnSelect
            getOptionLabel={(option) => option.skill_name}
            onChange={(event, value) => handleChange(event, value, "skills")}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                margin="normal"
                label="Skills"
                fullWidth
                required
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'transparent',
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                      boxShadow: 'none',
                    },
                  },
                  boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A',
                  borderRadius: '4px',
                  marginBottom: '1vw',
                  marginTop: '1.7vw'
                }}
                InputLabelProps={{
                  sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                  required: false
                }}
              />
            )}
          />
          <TextField
            className='mb-3'
            type="text"
            name="order_id"
            label="Order ID"
            variant="outlined"
            value={formValues.order_id}
            onChange={handleFormChange}
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent',
                  boxShadow: 'none',
                },
              },
              boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A',
            }}
            InputLabelProps={{
              sx: { "&.Mui-focused": { color: "#A8A8A8" } },
              required: false
            }}
          />
          <br />
          <TextField
            className='mb-3'
            type="text"
            label="Payment ID"
            name="payment_id"
            variant="outlined"
            value={formValues.payment_id}
            onChange={handleFormChange}
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent',
                  boxShadow: 'none',
                },
              },
              boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A',
            }}
            InputLabelProps={{
              sx: { "&.Mui-focused": { color: "#A8A8A8" } },
              required: false
            }}
          />
          <TextField
            className='mb-3'
            type="number"
            label="Transaction Amount"
            name="transaction_amount"
            variant="outlined"
            value={formValues.transaction_amount}
            onChange={handleFormChange}
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent',
                  boxShadow: 'none',
                },
              },
              boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A',
              borderRadius: '4px',
            }}
            InputLabelProps={{
              sx: { "&.Mui-focused": { color: "#A8A8A8" } },
              required: false
            }}
          />
          <TextField
            className='mb-3'
            type="number"
            label="Discount"
            name="discount"
            variant="outlined"
            value={formValues.discount}
            onChange={handleFormChange}
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent',
                  boxShadow: 'none',
                },
              },
              boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A',
            }}
            InputLabelProps={{
              sx: { "&.Mui-focused": { color: "#A8A8A8" } },
              required: false
            }}

          />
          <br />
          <div style={{ display: 'flex', alignItems: 'center', gap: '1.282vw' }}>
            <Button
              variant="contained"
              onClick={handleCloseDialog}
              className="mt-2"
              style={{ flex: '1', border: '2px solid #37446F', background: 'none', height: '2.25rem', color: 'black', textTransform: 'none' }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleAddNewTransaction}
              className="mt-2"
              style={{ flex: '1', background: '#37446F', height: '2.25rem', color: 'white', textTransform: 'none' }}
            >
              Add
            </Button>
          </div>

        </form>
      </Dialog>
    </>
  );
}

export default FinanceTabMobile;