import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Input,
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
  TextField,
  makeStyles,
  MenuItem,
  Dialog,
  Modal,
  InputAdornment,
  IconButton,
  TablePagination,
  TableRow,
  TableCell,
  DialogTitle,
  DialogActions,
  Grid,
  OutlinedInput
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import styles from "./Institute.module.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useLocalStorage } from "@uidotdev/usehooks";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InstituteMobile from "./InstituteMobile";
import isDesktop from "../isDesktop";

const instituteList = ["School", "College", "NGO", "Institute"];

const Institute = () => {
  const isDesktopDevice = isDesktop();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [aState, setAState] = useState("");
  const [pincode, setPincode] = useState("");
  const [token,setToken] = useLocalStorage('token',null);
  const [error, setError] = useState("");
  const [instituteType, setInstituteType] = useState("");
  const [country, setCountry] = useState("");
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [instituteData, setInstituteData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [instId,setInstId]=useState(-1)
  const [isUpdate, setIsUpdate] = useState(false);
  const [isdelete,setIsDelete]=useState(false)
  const [showPassword, setShowPassword] = useState(false);

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const displayedResults = filteredData.slice(startIndex, endIndex);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredData.length - page * rowsPerPage);
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(18);

  const clearFields=()=>{
    setName("")
    setEmail("")
    setMobile("")
    setPassword("")
    setCPassword("")
    setAddress("")
    setAState("")
    setCity("")
    setPincode("")
    setInstId(-1)
    setInstituteType("")
    setCountry("")
  }

  const getAllInstitutes = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/institute/getAll`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(response.data.data)
      setInstituteData(response.data.data);
    } catch (error) {
      toast.error("Error occured while fetching institute Data");
      console.error("Error occured while fetching institute Data", error);
    }
  };

  useEffect(() => {
    isDesktopDevice && getAllInstitutes();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (mobile.length !== 10) {
      setError("Invalid Mobile number");
      toast.error("Invalid Mobile number")
      return;
    } else {
      setError("");
    }
    // console.log(
    //   name,
    //   email,
    //   mobile,
    //   instituteType,
    //   password,
    //   cPassword,
    //   address,
    //   city,
    //   aState,
    //   pincode
    // );

    if (password !== cPassword) {
      toast.error("Password and Re-Enter Password do not Match");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/institute/add`,
        {
          name: name,
          email: email,
          mobile: mobile,
          institute_type: instituteType,
          password: password,
          address: address,
          city: city,
          state: aState,
          country:country,
          pincode: pincode,
        }
      );
      console.log(response);
      if (response.status === 200) {
        toast.success("Institute Registered Sucessfull");
        clearFields()
        getAllInstitutes()
      } else {
        toast.error("Error Registering Institute", response.data.message);
      }
    } catch (error) {
      toast.error("Error Registering Institute", error);
    }
  };

  const filterData = (query) => {
    if (query.length === 0) {
      setFilteredData(instituteData);
      return;
    }
    const temp = instituteData.filter(
      (v) =>
        v.institute_name.toLowerCase().includes(query.toLowerCase()) ||
        v.institute_email.toLowerCase().includes(query.toLowerCase())
    );
    console.log(temp);
    setFilteredData(temp);
  };

  // console.log(searchQuery);

  useEffect(() => {
    isDesktopDevice && filterData(searchQuery);
  }, [searchQuery, instituteData]);

  const onUpdateClick = (inst) => {
    setIsUpdate(true);
    console.log(inst);
    setName(inst.institute_name);
    setEmail(inst.institute_email);
    setMobile(inst.institute_mobile_no);
    setInstituteType(inst.institute_type);
    setAddress(inst.institute_address);
    setCountry(inst.country);
    setCity(inst.institute_city);
    setAState(inst.institute_state);
    setPincode(inst.institute_pincode);
    setInstId(inst.institute_id)
    setOpen(true);
  };

  const updateSubmitHandler=async(e)=>{
    e.preventDefault();
    if (mobile.length !== 10) {
      setError("Invalid Mobile number");
      toast.error("Invalid Mobile number")
      return;
    } else {
      setError("");
    }
    // console.log(
    //   name,
    //   email,
    //   mobile,
    //   instituteType,
    //   password,
    //   cPassword,
    //   address,
    //   city,
    //   aState,
    //   pincode
    // );

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/institute/update`,
        {
          id:instId,
          name: name,
          email: email,
          mobile: mobile,
          institute_type: instituteType,
          address: address,
          city: city,
          state: aState,
          country:country,
          pincode: pincode,
        }
      );
      console.log(response);
      if (response.status === 200) {
        toast.success("Institute Data updated Sucessfullt");
        clearFields()
        getAllInstitutes()
        setOpen(false)
        
      } else {
        toast.error("Error Updating Institute Data", response.data.message);
      }
    } catch (error) {
      toast.error("Error Updating Institute Data", error);
    }

  }

  const onDeleteClick=async(id)=>{
      try {
        const result=await axios.delete( `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/institute/delete/${id}`);

        if (result.status===200) {
          toast.success("Institute data deleted sucessfully");
          getAllInstitutes();
          setIsDelete(false)
        }
      } catch (error) { 
        console.error(error.response.data.message)
        toast.error("Error deleting institute")
      }
  }

  if(!isAuthorized) {
    return (
      <div className="container-fluid mt-5">
        <h2 style={{marginTop: '2rem'}}>You are unauthorized to access this page</h2>
      </div>
    )
  }
// console.log(page);
  return (
    <>
    {
      isDesktopDevice ? (
        <div className="container-fluid" style={{marginTop: '5vw'}}>
      <div className="align-center-all full-height">
        <Card className="col-md-12 p-3 shadow-none">
          <CardContent>
            <div className="top-div">
            <h2 className="page-header">Institute</h2>
            <ToastContainer />
            <div className={styles.inputContainer}>
              <div className={styles.textInputContainer}>
              <TablePagination
                rowsPerPageOptions={[5,10, 20, 30]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, newPage) => {
                  setPage(newPage);
                }}
                onRowsPerPageChange={(e) => {
                  console.log(e.target.value);
                  setRowsPerPage(parseInt(e.target.value, 10));
                  setPage(0);
                }}
              />
                <TextField
                  type="text"
                  placeholder="name, username, or email"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                            borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: 'transparent',
                        },
                    },
                    '& .MuiOutlinedInput-input': {
                      padding: '10.5px 14px !important',
                    },
                    boxShadow: '0px 2px 4px 0px #0000001A, 0px 2px 4px 0px #0000001A inset',
                    borderRadius: '0.513vw',
                    width: '15.385vw',
                    padding: '0'
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  variant="contained"
                  className="mr-2 filled-button"
                  onClick={() => {
                    setOpen((prev) => !prev);
                  }}
                >
                  Add
                </Button>
              </div>
            </div>
            </div>
            <table className="table">
              <thead className="table-head">
                <tr>
                  <th>S. No.</th>
                  <th className="text-left">Name</th>
                  <th>Institute Referral ID</th>
                  <th className="text-left">Email</th>
                  <th>Mobile</th>
                  <th>Institute Type</th>
                  {/* <th>Address</th> */}
                  <th>City</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {displayedResults.map((inst, ind) => {
                  return (
                    <tr key={ind}>
                      <td>{inst.institute_id}</td>
                      <td className="text-left">{inst.institute_name}</td>
                      <td>{inst.institute_referral_id}</td>
                      <td className="text-left">{inst.institute_email}</td>
                      <td>{inst.institute_mobile_no}</td>
                      <td>{inst.institute_type}</td>
                      {/* <td  >{inst.institute_address}</td> */}
                      <td>{inst.institute_city}</td>
                      <td className={styles.buttonsContainer}>
                        <Button
                          variant="contained"
                          className="b-but"
                          onClick={() => onUpdateClick(inst)}
                        >
                          Update
                        </Button>
                        <Button variant="contained" className="r-but"  onClick={()=>{setIsDelete(true); setInstId(inst.institute_id)}} >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </tbody>
            </table>
          </CardContent>

          <Dialog
            open={open}
            fullWidth
            onClose={() => {
              setOpen(false);
              setIsUpdate(false)
              clearFields()
            }}
            PaperProps={{
              style: {
                width: "68.718vw",
                maxWidth: "100vw",
                // height: "35.128vw",
                // padding: "1rem",
                borderRadius: "0.513vw",
                boxShadow:
                  "4px 4px 8px 0px #AEAEC099, box-shadow: -4px -4px 8px 0px #FFFFFF80",
              },
            }}
          >
            <div className={styles.dialogContainer}>
              <Typography
                variant="h5"
                gutterBottom
                className="mb-3 text-left"
              >
                {!isUpdate ? "Add Institute" : "Update Institute"}
              </Typography>
              <form onSubmit={!isUpdate ? handleSubmit : updateSubmitHandler} className={styles.mainForm}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      required
                      label="Name"
                      id="name"
                      type="text"
                      name="name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      className="customTextfield"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'transparent',
                            },
                            '&:hover fieldset': {
                                borderColor: 'transparent',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'transparent',
                            },
                        },
                        marginBottom: '0.625rem'
                    }}
                    />
                    <TextField
                      fullWidth
                      required
                      label="Mobile no"
                      id="mobille"
                      type="number"
                      error={error.length !== 0}
                      color={error.length !== 0 ? "error" : "primary"}
                      helperText={error.length !== 0 ? error : ""}
                      name="mobile"
                      value={mobile}
                      onChange={(e) => {
                        setMobile(e.target.value);
                      }}
                      className="customTextfield"
                      sx={{
                        input: {
                          "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                          {
                            "-webkit-appearance": "none",
                            margin: 0,
                          },
                          "&[type=number]": {
                            "-moz-appearance": "textfield",
                          },
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                              borderColor: 'transparent',
                          },
                          '&:hover fieldset': {
                              borderColor: 'transparent',
                          },
                          '&.Mui-focused fieldset': {
                              borderColor: 'transparent',
                          },
                      },
                      marginBottom: '0.625rem'
                      }}
                    />
                    <TextField
                      fullWidth
                      label="Address"
                      id="address"
                      type="text"
                      name="address"
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                      className="customTextfield"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'transparent',
                            },
                            '&:hover fieldset': {
                                borderColor: 'transparent',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'transparent',
                            },
                        },
                        marginBottom: '0.625rem'
                    }}
                    />
                    <TextField
                      required
                      fullWidth
                      label="State"
                      id="state"
                      type="text"
                      name="state"
                      value={aState}
                      onChange={(e) => {
                        setAState(e.target.value);
                      }}
                      className="customTextfield"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'transparent',
                            },
                            '&:hover fieldset': {
                                borderColor: 'transparent',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'transparent',
                            },
                        },
                        marginBottom: '0.625rem'
                    }}
                    />
                    {!isUpdate && (
                      <FormControl
                        fullWidth
                        variant="outlined"
                        className="customTextfield"
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'transparent',
                            },
                            '&:hover fieldset': {
                              borderColor: 'transparent',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'transparent',
                            },
                          },
                          marginBottom: '0.625rem'
                        }}
                      >
                        <InputLabel htmlFor="outlined-adornment-password">
                          Password
                        </InputLabel>
                        <OutlinedInput
                          fullWidth
                          name="password"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }} sx={{ backgroundColor: "transparent" }}
                          id="outlined-adornment-password"
                          type={showPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                style={{ background: "none" }}
                                onClick={() => {
                                  setShowPassword((prev) => !prev);
                                }}
                                onMouseDown={(e) => {
                                  e.preventDefault();
                                }}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                        />
                      </FormControl>
                    )}
                    {!isUpdate && (
                      <FormControl
                        fullWidth
                        variant="outlined"
                        className="customTextfield"
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'transparent',
                            },
                            '&:hover fieldset': {
                              borderColor: 'transparent',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'transparent',
                            },
                          },
                          marginBottom: '0.625rem'
                        }}
                      >
                        <InputLabel htmlFor="outlined-adornment-password">
                          Re-Enter Password
                        </InputLabel>
                        <OutlinedInput
                          fullWidth={true}
                          name="cpassword"
                          value={cPassword}
                          onChange={(e) => {
                            setCPassword(e.target.value);
                          }}
                          sx={{ backgroundColor: "transparent" }}
                          id="outlined-adornment-password"
                          type={showPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                style={{ background: "none" }}
                                onClick={() => {
                                  setShowPassword((prev) => !prev);
                                }}
                                onMouseDown={(e) => {
                                  e.preventDefault();
                                }}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                        />
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                  <TextField
                      fullWidth
                      required
                      label="Email"
                      id="email"
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      className="customTextfield"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'transparent',
                            },
                            '&:hover fieldset': {
                                borderColor: 'transparent',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'transparent',
                            },
                        },
                        marginBottom: '0.625rem'
                    }}
                    />
                    <TextField
                      id="insitute-type"
                      select
                      label="Institute Type"
                      defaultValue=""
                      value={instituteType}
                      required
                      onChange={(e) => {
                        setInstituteType(e.target.value);
                      }}
                      className="customTextfield"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'transparent',
                            },
                            '&:hover fieldset': {
                                borderColor: 'transparent',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'transparent',
                            },
                        },
                        marginBottom: '0.625rem'
                    }}
                    >
                      {instituteList.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      required
                      fullWidth
                      label="Country"
                      id="country"
                      type="text"
                      name="state"
                      value={country}
                      onChange={(e) => {
                        setCountry(e.target.value);
                      }}
                      className="customTextfield"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'transparent',
                            },
                            '&:hover fieldset': {
                                borderColor: 'transparent',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'transparent',
                            },
                        },
                        marginBottom: '0.625rem'
                    }}
                    />
                    <TextField
                      fullWidth
                      required
                      label="City"
                      id="city"
                      type="text"
                      name="city"
                      value={city}
                      onChange={(e) => {
                        setCity(e.target.value);
                      }}
                      className="customTextfield"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'transparent',
                            },
                            '&:hover fieldset': {
                                borderColor: 'transparent',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'transparent',
                            },
                        },
                        marginBottom: '0.625rem'
                    }}
                    />
                    <TextField
                      required
                      fullWidth
                      label="Pincode"
                      id="pincode"
                      type="number"
                      name="pincode"
                      value={pincode}
                      onChange={(e) => {
                        setPincode(e.target.value);
                      }}
                      className="customTextfield"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                              borderColor: 'transparent',
                          },
                          '&:hover fieldset': {
                              borderColor: 'transparent',
                          },
                          '&.Mui-focused fieldset': {
                              borderColor: 'transparent',
                          },
                      },
                      marginBottom: '0.625rem',
                        input: {
                          "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                          {
                            "-webkit-appearance": "none",
                            margin: 0,
                          },
                          "&[type=number]": {
                            "-moz-appearance": "textfield",
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <CardActions className="dialog-actions-box">
                <Button
                    type="button"
                    variant="outlined"
                    onClick={() => {
                      setOpen(false);
                      setIsUpdate(false)
                    }}
                    className="outlined-button"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" className="filled-button">
                    {isUpdate ? "Update" : "Register"}
                  </Button>
                </CardActions>
              </form>
            </div>
          </Dialog>
          <Dialog open={isdelete} onClose={()=>{setIsDelete(false)}} >
            <DialogTitle>Are you sure you want to delete this institute ?</DialogTitle>
            <DialogActions>
              <Button variant="contained" color="error"  onClick={()=>onDeleteClick(instId)} >Yes</Button>
              <Button variant="contained"  color="primary" onClick={()=>{setIsDelete(false)}} >No</Button>
            </DialogActions>
          </Dialog>
        </Card>
      </div>
    </div>
      ) : (
        <InstituteMobile />
      )
    }
    </>
  );
};

export default Institute;
