import React, { useState, useEffect } from "react";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { useLocalStorage } from "@uidotdev/usehooks";
import TextEditor from "../Components/TextEditor/TextEditor";
import { DialogActions, DialogContent, DialogContentText, FormControlLabel, Switch } from "@mui/material";

const Skills = () => {
  const [skills, setSkills] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [replicateDialogOpen, setReplicateDialogOpen] = useState(false);
  const [replicateSkillId, setReplicateSkillId] = useState(null);
  const [replicateSkillName, setReplicateSkillName] = useState("");
  const [replicateSkillOrder, setReplicateSkillOrder] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [skillToDelete, setSkillToDelete] = useState(null);
  const [newSkill, setNewSkill] = useState({
    name: "",
    skillRetry:false,
    fee: 0,
    discountedFee: 0,
    description: "",
    skillOrder: 0,
    paymentLink: "",
  });
  const [editSkill, setEditSkill] = useState({
    id: null,
    name: "",
    skillRetry:false,
    fee: 0,
    discountedFee: 0,
    description: "",
    skillOrder: 0,
    paymentLink: "",
  });

  const [token, setToken] = useLocalStorage("token", null);
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(4);

  const handleEdit = (skill) => {
    setEditSkill({...skill,skillRetry:skill.can_retry});
    setEditDialogOpen(true);
  };

  const handleEditSkills = async (skillsId) => {
    try {
      const requestData = {
        skillName: editSkill.skill_name,
        skillRetry:editSkill.skillRetry,
        description: editSkill.description,
        skillFee: editSkill.skill_fee,
        discountedFee: editSkill.discounted_fee,
        skillOrder: editSkill.skill_order,
        paymentLink: editSkill.payment_link,
      };

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/skills/update/${skillsId}`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        toast.success(`${response.data.message}`);
        handleEditDialogClose();
        fetchSkills();
      } else {
        toast.error(`Error updating skill: ${response.data.message}`);
      }
    } catch (error) {
      toast.error(
        `Error updating skill: ${
          error.response?.data?.message || error.message
        }`
      );
    }
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setEditSkill({
      id: null,
      name: "",
      skillRetry:false,
      fee: 0,
      discountedFee: 0,
      description: "",
      skillOrder: 0,
      paymentLink: "",
    });
  };

  const handleConfirmDelete = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/skills/delete/${skillToDelete}`;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.delete(apiUrl, config);

      if (response.status === 200) {
        console.log("Skill deleted successfully");
        fetchSkills();
        toast.success("Skill deleted successfully");
      } else {
        console.error("Error deleting skill: ", response.data.message);
        toast.error(`Error deleting skill: ${response.data.message}`);
      }
    } catch (error) {
      console.error("Error deleting skill: ", error);
      toast.error(`Error deleting skill: ${error.message}`);
    }
    setSkillToDelete(null);
    setDeleteOpen(false);
  };

  const handleDelete = async (skillsId) => {
    setDeleteOpen(true);
    setSkillToDelete(skillsId);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewSkill({
      name: "",
      fee: 0,
      discountedFee: 0,
      description: "",
      skillOrder: 0,
      paymentLink: "",
    });
  };

  const handleAddNewSkills = async () => {
    try {
      const requestData = {
        skillName: newSkill.name,
        skillRetry:newSkill.skillRetry,
        description: newSkill.description,
        skillFee: newSkill.fee,
        discountedFee: newSkill.discountedFee,
        skillOrder: newSkill.skillOrder,
        paymentLink: newSkill.paymentLink,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/skills/add`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        toast.success(`${response.data.message}`);
        handleCloseDialog();
        fetchSkills();
      } else {
        toast.error(`${response.data.message}`);
      }
    } catch (error) {
      toast.error(`${error.response.data.message}`);
    }
  };

  const fetchSkills = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/skills/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setSkills(response.data.data);
      } else {
        console.error("Error fetching skills: ", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching skills: ", error);
    }
  };

  useEffect(() => {
    fetchSkills();
  }, [token]);

  const handleReplicate = (skillId) => {
    setReplicateSkillId(skillId);
    setReplicateDialogOpen(true);
  };

  const handleReplicateDialogClose = () => {
    setReplicateDialogOpen(false);
    setReplicateSkillName("");
  };

  const handleReplicateSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/replicate/skills/${replicateSkillId}`,
        { skillName: replicateSkillName, skillOrder: replicateSkillOrder },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        toast.success(`${response.data.message}`);
        handleReplicateDialogClose();
        fetchSkills();
      } else {
        toast.error(`${response.data.message}`);
      }
    } catch (error) {
      toast.error(`${error.response.data.message}`);
    }
  };

  const changeDescription = (value) => {
    setEditSkill((prev) => ({
      ...prev,
      description: value,
    }));
  };

  const onDescriptionChange = (value) => {
    setEditSkill((prev) => ({
      ...prev,
      description: value,
    }));
  };

  console.log(editSkill.description);

  return (
    <div className="container-fluid mt-5 p-0">
      <div className="align-center-all full-height">
        {isAuthorized ? (
          <Card sx={{ boxShadow: "none" }}>
            <CardContent>
              <ToastContainer />
              <div className="top-div mt-5">
                <div className="page-header">Skills</div>
                <Button
                  variant="contained"
                  onClick={handleOpenDialog}
                  className="mr-2 filled-button"
                  style={{ float: "right" }}
                >
                  Add
                </Button>
              </div>
              <table className="table">
                <thead className="table-head">
                  <tr>
                    <th>S. No</th>
                    <th className="text-left">Name</th>
                    <th>Fee</th>
                    <th>Discounted Fee</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {skills.map((skill) => (
                    <tr key={skill.skill_id}>
                      <td>{skill.skill_id}</td>
                      <td className="text-left">{skill.skill_name}</td>
                      <td>{skill.skill_fee}</td>
                      <td>{skill.discounted_fee}</td>
                      <td>
                        <Button
                          variant="contained"
                          color="primary"
                          className="mr-2 g-but"
                          onClick={() => handleEdit(skill)}
                        >
                          Edit
                        </Button>
                        <Link to={`/skills/${skill.skill_id}/stage`}>
                          <Button variant="contained" className="mr-2 b-but">
                            Stage
                          </Button>
                        </Link>
                        <Button
                          variant="contained"
                          className="mr-2 y-but"
                          onClick={() => handleReplicate(skill.skill_id)}
                        >
                          Replicate
                        </Button>
                        <Button
                          aria-label="delete"
                          variant="contained"
                          className="r-but"
                          onClick={() => handleDelete(skill.skill_id)}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                PaperProps={{
                  sx: {
                    padding: "1vw",
                    width: "35.128vw",
                  },
                }}
              >
                <DialogTitle style={{ padding: 0, fontWeight: "600" }}>
                  Add New Skills
                </DialogTitle>
                <TextField
                  label="New Skill Name"
                  value={newSkill.name}
                  onChange={(e) =>
                    setNewSkill({ ...newSkill, name: e.target.value })
                  }
                  fullWidth
                  margin="normal"
                  className="customTextfield"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent",
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent",
                      },
                    },
                  }}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={newSkill.skillRetry}
                      onChange={(e) =>
                        setNewSkill({ ...newSkill, skillRetry: e.target.checked ? "1" : "0" })
                      }
                      name="Skill Retry"
                      color="primary"
                    />
                  }
                  label="Skill Retry"
                />
                <TextField
                  label="Description"
                  value={newSkill.description}
                  onChange={(e) =>
                    setNewSkill({ ...newSkill, description: e.target.value })
                  }
                  fullWidth
                  margin="normal"
                  className="customTextfield"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent",
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent",
                      },
                    },
                  }}
                />
                <TextField
                  label="Fee"
                  type="number"
                  value={newSkill.fee}
                  onChange={(e) =>
                    setNewSkill({ ...newSkill, fee: e.target.value })
                  }
                  fullWidth
                  margin="normal"
                  className="customTextfield"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent",
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent",
                      },
                    },
                  }}
                />
                <TextField
                  label="Discounted Fee"
                  type="number"
                  value={newSkill.discountedFee}
                  onChange={(e) =>
                    setNewSkill({
                      ...newSkill,
                      discountedFee: e.target.value,
                    })
                  }
                  fullWidth
                  margin="normal"
                  className="customTextfield"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent",
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent",
                      },
                    },
                  }}
                />
                <TextField
                  label="Skill Order"
                  type="number"
                  value={newSkill.skillOrder}
                  onChange={(e) =>
                    setNewSkill({ ...newSkill, skillOrder: e.target.value })
                  }
                  fullWidth
                  margin="normal"
                  className="customTextfield"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent",
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent",
                      },
                    },
                  }}
                />
                {/* <TextField
                    label="Payment Link"
                    value={newSkill.paymentLink}
                    onChange={(e) =>
                      setNewSkill({ ...newSkill, paymentLink: e.target.value })
                    }
                    fullWidth
                    margin="normal"
                    className="customTextfield"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent",
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                    }}
                  /> */}
                <div className="dialog-actions-box">
                  <Button
                    variant="outlined"
                    onClick={handleCloseDialog}
                    className="mt-2 mr-1 outlined-button"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleAddNewSkills}
                    className="mt-2 filled-button"
                  >
                    Add
                  </Button>
                </div>
              </Dialog>

              <Dialog
                open={editDialogOpen}
                onClose={handleEditDialogClose}
                PaperProps={{
                  sx: {
                    padding: "1vw",
                    width: "35.128vw",
                  },
                }}
              >
                <DialogTitle style={{ padding: 0, fontWeight: "600" }}>
                  Edit Skills
                </DialogTitle>
                <TextField
                  label="Name"
                  value={editSkill.skill_name}
                  onChange={(e) =>
                    setEditSkill({ ...editSkill, skill_name: e.target.value })
                  }
                  fullWidth
                  margin="normal"
                  className="customTextfield"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent",
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent",
                      },
                    },
                  }}
                />
                 <FormControlLabel
                  control={
                    <Switch
                      checked={editSkill.skillRetry}
                      onChange={(e) =>
                        setEditSkill({ ...editSkill, skillRetry: e.target.checked})
                      }
                      name="Skill Retry"
                      color="primary"
                    />
                  }
                  label="Skill Retry"
                />
                <div className="descriptionContainer">
                  <TextEditor
                    propsContent={editSkill.description}
                    propPlaceholder={"Short Description...."}
                    onChangeContent={onDescriptionChange}
                  />
                </div>
                {/* <TextField
                    label="Description"
                    value={editSkill.description}
                    onChange={(e) =>
                      setEditSkill({ ...editSkill, description: e.target.value })
                    } 
                    fullWidth
                    margin="normal"
                    className="customTextfield"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent",
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                    }}
                  /> */}
              
                <TextField
                  label="Fee"
                  type="number"
                  value={editSkill.skill_fee}
                  onChange={(e) =>
                    setEditSkill({ ...editSkill, skill_fee: e.target.value })
                  }
                  fullWidth
                  margin="normal"
                  className="customTextfield"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent",
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent",
                      },
                    },
                  }}
                />
                <TextField
                  label="Discounted Fee"
                  type="number"
                  value={editSkill.discounted_fee}
                  onChange={(e) =>
                    setEditSkill({
                      ...editSkill,
                      discounted_fee: e.target.value,
                    })
                  }
                  fullWidth
                  margin="normal"
                  className="customTextfield"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent",
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent",
                      },
                    },
                  }}
                />
                <TextField
                  label="Skill Order"
                  type="number"
                  value={editSkill.skill_order}
                  onChange={(e) =>
                    setEditSkill({
                      ...editSkill,
                      skill_order: e.target.value,
                    })
                  }
                  fullWidth
                  margin="normal"
                  className="customTextfield"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent",
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent",
                      },
                    },
                  }}
                />
                {/* <TextField
                    label="Payment Link"
                    value={editSkill.payment_link}
                    onChange={(e) =>
                      setEditSkill({
                        ...editSkill,
                        payment_link: e.target.value,
                      })
                    }
                    fullWidth
                    margin="normal"
                    className="customTextfield"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent",
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                    }}
                  /> */}
                <div className="dialog-actions-box">
                  <Button
                    variant="outlined"
                    onClick={handleEditDialogClose}
                    className="mt-2 mr-1 outlined-button"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => handleEditSkills(editSkill.skill_id)}
                    className="mt-2 filled-button"
                  >
                    Save
                  </Button>
                </div>
              </Dialog>
              <Dialog
                open={replicateDialogOpen}
                onClose={handleReplicateDialogClose}
                PaperProps={{
                  sx: {
                    padding: "1vw",
                    width: "35.128vw",
                  },
                }}
              >
                <DialogTitle style={{ padding: "0 1rem", fontWeight: "600" }}>
                  Replicate Skill
                </DialogTitle>
                <form className="m-2">
                  <TextField
                    label="New Skill Name"
                    value={replicateSkillName}
                    onChange={(e) => setReplicateSkillName(e.target.value)}
                    fullWidth
                    margin="normal"
                    className="customTextfield"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent",
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                    }}
                  />
                  <TextField
                    label="Skill Order"
                    type="number"
                    value={replicateSkillOrder}
                    onChange={(e) => setReplicateSkillOrder(e.target.value)}
                    fullWidth
                    margin="normal"
                    className="customTextfield"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent",
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                    }}
                  />
                  <div className="dialog-actions-box mt-1">
                    <Button
                      variant="outlined"
                      onClick={handleReplicateDialogClose}
                      className="mt-2 mr-1 outlined-button"
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleReplicateSubmit}
                      className="mt-2 filled-button"
                    >
                      Replicate
                    </Button>
                  </div>
                </form>
              </Dialog>
              <Dialog
                open={deleteOpen}
                onClose={(prev) => !prev}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ textAlign: "center" }}
              >
                <ErrorOutlineIcon
                  style={{
                    color: "red",
                    fontSize: "32px",
                    margin: "12px auto",
                  }}
                />
                <DialogTitle
                  id="alert-dialog-title"
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    lineHeight: "0",
                  }}
                >
                  {"Are you Sure?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText
                    id="alert-dialog-description"
                    style={{ fontSize: "12px" }}
                  >
                    You won't be able to revert this!
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setDeleteOpen(false);
                      setSkillToDelete(null);
                    }}
                    style={{
                      textTransform: "none",
                      width: "120px",
                      height: "36px",
                      border: "2.03px solid #37446F",
                      borderRadius: "5.85px",
                      background: "none",
                      color: "black",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleConfirmDelete}
                    style={{
                      textTransform: "none",
                      width: "120px",
                      height: "36px",
                      borderRadius: "5.85px",
                      background: "#37446F",
                      color: "white",
                    }}
                    autoFocus
                  >
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </CardContent>
          </Card>
        ) : (
          <h2>You are unauthorized to access this page.</h2>
        )}
      </div>
    </div>
  );
};

export default Skills;
