import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  IconButton,
  Collapse,
  Table,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  TableCell,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { tableCellClasses } from "@mui/material/TableCell";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import navLink from "../navLinks/Navlinks";
import roleService from "./roleService";
import AddRoleDialog from "./AddRoleDialog";
import UpdateRoleDialog from "./UpdateRoleDialog";
import './Roles.css'
import { useLocalStorage } from "@uidotdev/usehooks";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { toast } from "react-toastify";
import { ReactComponent as Hemberger } from '../assets/icons/Hemberger.svg';
import { ReactComponent as UserIcon } from '../assets/icons/UserIcon.svg';
import Sidebar from "../sidenav/Sidenav";
import { Link } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


const RolesMobile = () => {
  /* on Successfull Update/Add Dialog */
  const [openSuccessfulMessage, setOpenSuccessfulMessage] = React.useState(false);
  const [isSuccessfullForAdd, setIsSuccessfullForAdd] = React.useState(false);
  const handleClose = () => {
    setOpenSuccessfulMessage(false);
    setIsSuccessfullForAdd(false);
  };
  const [isAddDialogOpen, setAddDialogOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [allSkills, setAllSkills] = useState([]);
  const [formValues, setFormValues] = React.useState({ username: '', email: '', role: '', phone: '', order_id: '' });

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    console.log({ ...formValues });
  };
  const [roles, setRoles] = useState([]);
  const [newRole, setNewRole] = useState({ tabId: "", roleName: "" });
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedTabs, setSelectedTabs] = useState([]);
  const [isUpdateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [selectedRoleForUpdate, setSelectedRoleForUpdate] = useState(null);

  const [token, setToken] = useLocalStorage('token', null);
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(8);

  const fetchRoles = async () => {
    try {
      const activeRoles = await roleService.getRoles(token);
      setRoles(activeRoles);
    } catch (error) {
      console.error("Error fetching roles:", error.response);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);
  // console.log(roles);
  
  const handleUpdate = (roleId) => {
    // console.log(roleId);

    setSelectedTabs(roles && JSON.parse(roles.find((role) => role.role_id === roleId).tab_id));
    const selectedRole = roles.find((role) => role.role_id === roleId);
    setSelectedRoleForUpdate(selectedRole);
    console.log(selectedRole, " selectedRoleForUpdate");
    console.log(selectedTabs, " selectedTabs");
    setUpdateDialogOpen(true);
    // setOpenDialog(true)
  };
  // console.log(selectedTabs);

  const handleUpdateSave = () => {
    const role = selectedRoleForUpdate.role_id;
    const tabIds = selectedTabs;
    // console.log(role, tabIds, " ye hua update"); // O/P: 1, (4) [1, 14, 13, 21] ' ye hua update' 
    roleService
      .updateRole(token, role, tabIds)
      .then((response) => {
        console.log("Role updated successfully:", response);
        handleCloseUpdateDialog();
        setOpenSuccessfulMessage(true);
        fetchRoles();
      })
      .catch((error) => {
        console.error("Error updating role:", error);
        toast.error("Error updating role:", error);
      });
  };

  const handleAdd = () => {
    setAddDialogOpen(true);
  };

  const handleCloseAddDialog = () => {
    setAddDialogOpen(false);
    setSelectedTabs([]);
    setNewRole("");
  };

  const handleCloseUpdateDialog = () => {
    setUpdateDialogOpen(false);
    setSelectedTabs([]);
  };

  const handleSave = () => {
    if (!newRole.roleName || selectedTabs.length === 0) {
      console.error("Please enter Role Name and select at least one tab");
      toast.error("Please enter Role Name and select at least one tab");
      return;
    }
    // console.log(newRole.roleName, selectedTabs, " ye hua save");
    roleService
      .createRole(token, {
        role: newRole.roleName,
        tabIds: selectedTabs,
      })
      .then((response) => {
        console.log("Role created successfully:", response);
        // toast.success("Role created successfully:", response);
        handleCloseAddDialog();
        setIsSuccessfullForAdd(true);
        setOpenSuccessfulMessage(true);
        fetchRoles();
      })
      .catch((error) => {
        console.error("Error creating role:", error);
        toast.error("Error creating role:", error);
      });
  };

  const handleTabToggle = (tabId) => {
    console.log(tabId, " tabId");
    const updatedTabs = selectedTabs.includes(tabId)
      ? selectedTabs.filter((id) => id !== tabId)
      : [...selectedTabs, tabId];

    setSelectedTabs(updatedTabs);
  };

  const [open, setOpen] = React.useState(false);
  const handleDelete = (roleId) => {
    setOpen(true);
    setSelectedRole(roleId);
  };
  const handleConfirmDelete = () => {
    console.log(selectedRole, " selectedRole");
    if (selectedRole) {
      roleService
        .deleteRole(token, selectedRole)
        .then((response) => {
          console.log("Role deleted successfully:", response);
          setOpen(false);
          toast.success("Role deleted successfully:", response);
          fetchRoles();
        })
        .catch((error) => {
          console.error("Error deleting role:", error);
          toast.error("Error deleting role:", error);
        });
    }
  }

  const handleOpenDialog = () => {
    setOpenDialog(true)
  }
  const handleCloseDialog = () => {
    // setOpenDialog(false)
    setUpdateDialogOpen(false);
    setSelectedTabs([]);
  }

  /* MUI Table */
  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    if (!isAuthorized) {
      return (
        <div className="container-fluid mt-5">
          <h2>You are unauthorized to access this page</h2>
        </div>
      );
    }

    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell component="th" scope="row">
            <div>{row?.role}</div>
          </TableCell>
          <TableCell style={{textAlign: 'end'}}>
            <IconButton
              aria-label="expand row"
              size="small"
              style={{color: 'black'}}
              onClick={() => setOpen(!open)}
            >
              {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow sx={{ "& td": { border: 0, height: open ? 'auto' : 0 }, borderBottom: '1px solid black', }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderTop: 'none' }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit  >
              <div style={{ display: 'grid', gridTemplateColumns: '1.3rem 1fr', columnGap: '1rem', fontSize: '0.625rem', marginBottom: '0.25rem' }}>
                <div>Role:</div>
                <div>{row?.tab_id}</div>
              </div>
              <div style={{fontSize: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '0.5rem'}}>
                <div style={{flex: '1'}}>Actions:</div>
                <div>
                  <button className='actionBtn' style={{ backgroundColor: '#CBEBF6', boxShadow: '2px 2px 4px 0px #AEAEC080', marginRight: '0.5rem' }} onClick={() => handleUpdate(row.role_id)}>Update</button>
                  <button className='actionBtn' style={{ backgroundColor: '#FFD6D2', boxShadow: '2px 2px 4px 0px #AEAEC080' }} onClick={() => handleDelete(row?.role_id)}>Delete</button>
                </div>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [logoutDialog, setLogoutDialog] = useState(false);
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '0.5rem 0.75rem 1.25rem 0.75rem' }}>
        <div onClick={() => setIsSidebarOpen(!isSidebarOpen)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', width: '36px', height: '36px', borderRadius: '18px', background: '#EAF1F7' }}>
          <Hemberger />
        </div>
        <Link to={'/profile'}>
          <UserIcon style={{ cursor: 'pointer' }} />
        </Link>
      </div>
      <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} setLogoutDialog={setLogoutDialog} />

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '0rem 0.75rem 1.5rem 0.75rem' }}>
        <h4>Roles</h4>
        <Button
          className="button-two"
          onClick={handleAdd}
          style={{ width: '6rem', height: '1.75rem', background: '#37446F' }}
        >
          Add
        </Button>
      </div>

      <Table aria-label="collapsible table" sx={{
        [`& .${tableCellClasses.root}`]: {
          borderBottom: "none"
        }
      }}>
        <TableHead style={{ height: '44px', borderRadius: '4px !important', background: 'rgba(234, 241, 247, 1)', boxShadow: '2px 2px 4px 0px rgba(174, 174, 192, 0.5)' }}>
          <TableRow>
            <TableCell>Name</TableCell>
            {/* <TableCell align="right">Institute type & City</TableCell> */}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {roles
            ?.map((role, ind) => (
              <Row key={ind} row={role} />
            ))}
        </TableBody>
      </Table>

      <AddRoleDialog
        isOpen={isAddDialogOpen}
        onClose={handleCloseAddDialog}
        onSave={handleSave}
        onTabToggle={handleTabToggle}
        selectedTabs={selectedTabs}
        newRole={newRole}
        setNewRole={setNewRole}
        navLink={navLink}
      />
      {/* Update */}
      <UpdateRoleDialog
        isOpen={isUpdateDialogOpen}
        onClose={handleCloseDialog}
        onSave={handleUpdateSave}
        onTabToggle={handleTabToggle}
        selectedTabs={selectedTabs}
        selectedRoleForUpdate={selectedRoleForUpdate}
        navLink={navLink}
      />

      {/* Delete Dialog Box */}
      <Dialog
        open={open}
        onClose={(prev) => !prev}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ textAlign: 'center' }}
      >
        <ErrorOutlineIcon style={{ color: 'red', fontSize: '32px', margin: '12px auto' }} />
        <DialogTitle id="alert-dialog-title" style={{ fontSize: '18px', fontWeight: '500', lineHeight: '0' }}>
          {"Are you Sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ fontSize: '12px' }}>
            You won't be able to revert this!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} style={{ textTransform: 'none', width: '120px', height: '36px', border: '2.03px solid #37446F', borderRadius: '5.85px', background: 'none', color: 'black' }}>Cancel</Button>
          <Button onClick={handleConfirmDelete} style={{ textTransform: 'none', width: '120px', height: '36px', borderRadius: '5.85px', background: '#37446F', color: 'white' }} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Successfull Message: Dialog Box */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openSuccessfulMessage}
      >
        <div style={{ marginBottom: '2rem' }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={(theme) => ({
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div style={{ width: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', padding: '3rem', paddingTop: '0' }}>
          <CheckCircleIcon style={{ color: 'green', fontSize: '2rem', margin: '1rem' }} />
          <h4>{isSuccessfullForAdd ? "Added" : "Updated"} Successful!</h4>
          <p style={{ textAlign: 'center', fontSize: '0.75rem' }}>Category has been {isSuccessfullForAdd ? "added" : "updated"} successfully.</p>
        </div>
      </BootstrapDialog>
    </>
  );
};

export default RolesMobile;
