import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import axios from 'axios';
import { TextField, TablePagination } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { toast, ToastContainer } from 'react-toastify';
import Autocomplete from "@mui/material/Autocomplete";
import { InputLabel } from "@mui/material";
import { useLocalStorage } from '@uidotdev/usehooks';
import FinanceTabMobile from './financeTabMobile';
import isDesktop from '../isDesktop';

const FinanceTab = () => {
    const isDesktopDevice = isDesktop();
    const [token, setToken] = useLocalStorage('token', null);
    const [transactions, setTransactions] = useState([]);
    const [filteredTransactions, setFilteredTransactions] = useState([]);
    const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
    const isAuthorized = userTabIds.includes(15);
    const [searchQuery, setSearchQuery] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [allSkills, setAllSkills] = useState([]);
    const [allUsers, setAllUsers] = useState([]);

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    };
    const handleChangeUser = (event, value, name) => {
        console.log(event, value, name, " got username");
        setFormValues((prevState) => ({
            ...prevState,
            [event.target.name || name]: value?.username,
        }));
    }
    const handleChange = (event, value, name) => {
        let newValue;
        // console.log(value);

        if (value) {
            newValue = value.map((item) => item.skill_id);
        } else {
            newValue = [];
        }
        setFormValues((prevState) => ({
            ...prevState,
            [event.target.name || name]: newValue,
        }));
    };


    const [formValues, setFormValues] = useState({
        username: '',
        skills: '',
        order_id: '',
        payment_id: '',
        transaction_amount: '',
        discount: '',
    });

    const handleCloseDialog = () => {
        setFormValues({
            username: '',
            skills: '',
            order_id: '',
            payment_id: '',
            transaction_amount: '',
            discount: '',
        })
        setOpenDialog(false);
    };

    const fetchSkills = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/skills/all`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                setAllSkills(response.data.data);
            } else {
                console.error("Error fetching skills: ", response.data.message);
            }
        } catch (error) {
            console.error("Error fetching skills: ", error);
        }
    };

    const formatDate = (date) => {
        // console.log(date);

        date = new Date(date);
        // Add 5 hours and 30 minutes
        // let ISTOffset = 5.5 * 60 * 60 * 1000; // in ms
        // let ISTDate = new Date(date.getTime());

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        // day = day < 10 ? '0' + day : day;
        // month = month < 10 ? '0' + month : month;
        let formattedDate = `${day}.${month}.${year}`;

        let hours = date.getHours();
        let minutes = date.getMinutes();
        let AmOrPm = hours >= 12 ? 'pm' : 'am';
        hours = (hours % 12) || 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        let formattedTime = `${hours}:${minutes}${AmOrPm}`;
        return { time: formattedTime, date: formattedDate };
    };

    const fetchTransactions = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/finance/all`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                // console.log(response.data.data);

                setTransactions(response.data.data);
                filterTransactions(searchQuery, response.data.data);
            } else {
                console.error('Error fetching transactions: ', response.data.message);
            }
        } catch (error) {
            console.error('Error fetching transactions: ', error);
        }
    };
    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/user/all`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if(response.status === 200) {
                setAllUsers(response.data.data);
            } else{
                console.error('Error fetching users: ', response.data.message);
            }
        } catch (error) {
            console.error('Error fetching users: ', error);
        }
    }

    const filterTransactions = (query, data) => {
        const filtered = data.filter(transaction =>
            transaction?.name.toLowerCase().includes(query.toLowerCase()) ||
            transaction?.username.toLowerCase().includes(query.toLowerCase()) ||
            transaction?.skill_names?.toLowerCase().includes(query.toLowerCase()) ||
            transaction?.order_id.toLowerCase().includes(query.toLowerCase()) ||
            transaction?.payment_id.toLowerCase().includes(query.toLowerCase()) ||
            transaction?.transaction_amount.toString().includes(query)
        );
        // console.log(filtered);

        setFilteredTransactions(filtered);
    };

    useEffect(() => {
        if(isDesktopDevice) {
            fetchTransactions();
            fetchSkills();
            fetchUsers();
        }
    }, []);

    useEffect(() => {
        if(isDesktopDevice) {
            filterTransactions(searchQuery, transactions);
            // console.log(transactions, " Transactioon");
            transactions.reverse();
            // console.log(transactions, " Transactioon Reverse");
        }
    }, [searchQuery, transactions]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true)
    }

    const handleAddNewTransaction = async () => {
        const { name, skills, order_id, payment_id, transaction_amount, discount } = formValues;
        if (!name || !skills || !order_id || !payment_id || !transaction_amount || !discount) {
            toast.error('All fields are required');
            return;
        }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/finance/add`,
                {
                    username: name,
                    skills,
                    order_id,
                    payment_id,
                    transaction_amount,
                    discount
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                toast.success('Transaction added successfully');
                fetchTransactions();
                handleCloseDialog();
            } else {
                toast.error('Username not found: ', response.data.message);
            }
        } catch (error) {
            toast.error('Username not found: ', error);
        }
    };
    // console.log(transactions);

    /* Pagination | MUI */
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    }
    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(+e.target.value);
        setPage(0);
    }
    
    return (
        <>
            {
                isAuthorized ? (
                    isDesktopDevice ? (
                        <>
                            <div className="container-fluid" style={{ paddingTop: '4.5vw' }}>
                                <div className="align-center-all full-height">
                                    <Card elevation={3} className="col-md-12 p-3" style={{ boxShadow: 'none' }}>
                                        <CardContent>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '2.179vw' }}>
                                                <h2 className='page-header'>Finance</h2>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <TablePagination
                                                        component="div"
                                                        className='mr-2'
                                                        rowsPerPageOptions={[10, 20, 30]}
                                                        count={filteredTransactions.length}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        rowsPerPage={rowsPerPage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                    />
                                                    <TextField
                                                        className="mr-2"
                                                        variant="outlined"
                                                        placeholder="Search..."
                                                        sx={{
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: 'transparent',
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'transparent',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'transparent',
                                                                    boxShadow: 'none',
                                                                },
                                                            },
                                                        }}
                                                        InputProps={{
                                                            style: {
                                                                height: '2.564vw',
                                                                boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                                                            },
                                                            endAdornment: <SearchIcon />,
                                                        }}
                                                        value={searchQuery}
                                                        onChange={handleSearchChange}
                                                    />
                                                    <Button variant="contained" style={{ width: '15.385vw', background: '#37446F', height: '2.564vw', color: 'white', textTransform: 'none' }} onClick={handleOpenDialog}>
                                                        Add
                                                    </Button>
                                                </div>
                                            </div>
                                            <table className="table">
                                                <thead style={{ height: '44px', borderRadius: '4px !important', background: 'rgba(234, 241, 247, 1)', boxShadow: '2px 2px 4px 0px rgba(174, 174, 192, 0.5)' }}>
                                                    <tr>
                                                        <th style={{ borderTopLeftRadius: '4px' }}>S. No.</th>
                                                        <th style={{ textAlign: 'left' }}>Name & Username</th>
                                                        <th style={{ textAlign: 'left', width: '25.641vw' }}>Skills/Labs</th>
                                                        <th>Order ID</th>
                                                        <th>Pay ID</th>
                                                        <th>Amount</th>
                                                        <th style={{ borderTopRightRadius: '4px' }}>Date & Time</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredTransactions?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        ?.map((transaction) => (
                                                            <tr key={transaction.transaction_id}>
                                                                <td>{transaction?.transaction_id}</td>
                                                                <td style={{ textAlign: 'left' }}>
                                                                    <>
                                                                        <p style={{ padding: '0', margin: '0' }}>{transaction?.name}</p>
                                                                        <p style={{ padding: '0', margin: '0' }}>{transaction?.username}</p>
                                                                    </>
                                                                </td>
                                                                <td style={{ textAlign: 'left' }}>{transaction?.skill_names}</td>
                                                                <td>{transaction?.order_id}</td>
                                                                <td>{transaction?.payment_id}</td>
                                                                <td>{transaction?.transaction_amount}</td>
                                                                <td>
                                                                    <>
                                                                        <p style={{ padding: '0', margin: '0' }}>{formatDate(transaction.created_at)?.date}</p>
                                                                        <p style={{ padding: '0', margin: '0' }}>{formatDate(transaction.created_at)?.time}</p>
                                                                    </>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </CardContent>
                                    </Card>
                                </div>
                            </div >
                            <Dialog open={openDialog} onClose={handleCloseDialog}>
                                <DialogTitle style={{ fontWeight: '600', paddingBottom: '0' }}>Add Transaction</DialogTitle>
                                <form style={{ padding: '18px 24px' }}>
                                    <Autocomplete
                                        id="users"
                                        name="name"
                                        options={allUsers}
                                        getOptionLabel={(option) => option.username}
                                        onChange={(event, value) => handleChangeUser(event, value, "name")}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                margin="normal"
                                                label="Username"
                                                fullWidth
                                                required
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: 'transparent',
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: 'transparent',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: 'transparent',
                                                            boxShadow: 'none',
                                                        },
                                                    },
                                                    boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A',
                                                    borderRadius: '4px',
                                                    marginBottom: '1vw',
                                                    marginTop: '1.7vw'
                                                }}
                                                InputLabelProps={{
                                                    sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                                                    required: false
                                                }}
                                            />
                                        )}
                                    />
                                    <Autocomplete
                                        multiple
                                        id="skills"
                                        name="skills"
                                        options={allSkills}
                                        getOptionLabel={(option) => option.skill_name}
                                        onChange={(event, value) => handleChange(event, value, "skills")}
                                        disableCloseOnSelect={true}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                margin="normal"
                                                label="Skills"
                                                fullWidth
                                                required
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: 'transparent',
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: 'transparent',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: 'transparent',
                                                            boxShadow: 'none',
                                                        },
                                                    },
                                                    boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A',
                                                    borderRadius: '4px',
                                                    marginBottom: '1vw',
                                                    marginTop: '1.7vw'
                                                }}
                                                InputLabelProps={{
                                                    sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                                                    required: false
                                                }}
                                            />
                                        )}
                                    />
                                    <TextField
                                        type="text"
                                        name="order_id"
                                        label="Order ID"
                                        variant="outlined"
                                        value={formValues.order_id}
                                        onChange={handleFormChange}
                                        fullWidth
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: 'transparent',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: 'transparent',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: 'transparent',
                                                    boxShadow: 'none',
                                                },
                                            },
                                            boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A',
                                            borderRadius: '4px',
                                            marginBottom: '0.3vw',
                                            marginTop: '0.8vw'
                                        }}
                                        InputLabelProps={{
                                            sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                                            required: false
                                        }}
                                    />
                                    <TextField
                                        type="text"
                                        label="Payment ID"
                                        name="payment_id"
                                        variant="outlined"
                                        value={formValues.payment_id}
                                        onChange={handleFormChange}
                                        fullWidth
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: 'transparent',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: 'transparent',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: 'transparent',
                                                    boxShadow: 'none',
                                                },
                                            },
                                            boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A',
                                            borderRadius: '4px',
                                            marginBottom: '1.538vw',
                                            marginTop: '1.538vw'
                                        }}
                                        InputLabelProps={{
                                            sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                                            required: false
                                        }}
                                    />
                                    <TextField
                                        type="number"
                                        label="Transaction Amount"
                                        name="transaction_amount"
                                        variant="outlined"
                                        value={formValues.transaction_amount}
                                        onChange={handleFormChange}
                                        fullWidth
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: 'transparent',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: 'transparent',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: 'transparent',
                                                    boxShadow: 'none',
                                                },
                                            },
                                            boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A',
                                            borderRadius: '4px',
                                        }}
                                        InputLabelProps={{
                                            sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                                            required: false
                                        }}
                                    />
                                    <TextField
                                        type="number"
                                        label="Discount"
                                        name="discount"
                                        variant="outlined"
                                        value={formValues.discount}
                                        onChange={handleFormChange}
                                        fullWidth
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: 'transparent',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: 'transparent',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: 'transparent',
                                                    boxShadow: 'none',
                                                },
                                            },
                                            boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A',
                                            borderRadius: '4px',
                                            marginBottom: '1.538vw',
                                            marginTop: '1.538vw'
                                        }}
                                        InputLabelProps={{
                                            sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                                            required: false
                                        }}

                                    />
                                    <br />
                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.641vw', gap: '1.282vw' }}>
                                        <Button
                                            onClick={() => {
                                                setOpenDialog(false);
                                                setFormValues({
                                                    username: '',
                                                    skills: '',
                                                    order_id: '',
                                                    payment_id: '',
                                                    transaction_amount: '',
                                                    discount: '',
                                                })
                                            }}
                                            className="mt-2 outlined-button"
                                            style={{ flex: '1' }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            onClick={handleAddNewTransaction}
                                            className="mt-2 filled-button"
                                            style={{ flex: '1' }}
                                        >
                                            Add
                                        </Button>
                                    </div>

                                </form>
                            </Dialog>
                        </>
                    ) : (
                        <FinanceTabMobile />
                    )
                ) : (
                    <h2 style={{marginTop: '5%'}}>You are unauthorized to access this page.</h2>
                )
            }
        </>
    );
}

export default FinanceTab;