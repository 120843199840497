import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { InputLabel } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import "./UserCart.css"
import { useLocalStorage } from "@uidotdev/usehooks";
import UserCartMobile from "./UserCartMobile";
import isDesktop from "../isDesktop";

const UserCart = () => {
  const isDesktopDevice = isDesktop();
  const [formData, setFormData] = useState({
    skill: [],
    specialization: [],
    userId: [],
    discount: "",
  });

  const [skills, setSkills] = useState([]);
  const [specialization, setSpecialization] = useState([])
  const [users, setUsers] = useState([]);

  const [token, setToken] = useLocalStorage('token', null);
  const empId = localStorage.getItem("userId");
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(13);
  const [discount, setDiscount] = useState();
  const [skillFees, setSkillFees] = useState({}); // State to hold skill fees

  const [totalBill, setTotalBill] = useState(0);
  const [finalBill, setFinalBill] = useState(0);

  const fetchSkills = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/skills/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setSkills(response.data.data);
      } else {
        console.error("Error fetching skills: ", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching skills: ", error);
    }
  };

  const fetchSpecializations = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/specialization/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )


      if (response.status === 200) {
        console.log(response.data.data);
        setSpecialization(response.data.data)
      }
    } catch (error) {
      console.error("Error fetching specialization data", error);

    }
  }

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/user/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setUsers(response.data.data);
      } else {
        console.error("Error fetching users: ", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching users: ", error);
    }
  };

  useEffect(() => {
    if (isDesktopDevice && token && token!=="null") {
      // console.log(token);

      fetchSkills();
      fetchUsers();
      fetchSpecializations()
    }
  }, [token]);

  useEffect(() => {
    // Calculate total fee when formData.skill changes
    const calculateTotalFee = () => {
      console.log(formData);

      let totalFee = 0;
      formData?.skill.forEach((skillId) => {
        const selectedSkill = skills.find((skill) => skill.skill_id === skillId);
        if (selectedSkill) {
          // Add skill fee to total fee
          totalFee += selectedSkill.discounted_fee;
        }
      });
      formData?.specialization.forEach((spec_id) => {
        const selectedSpecialization = specialization.find((specs) => { return specs.specialization_id === spec_id })
        if (selectedSpecialization) {
          totalFee += selectedSpecialization.discounted_fee
        }
      })
      setTotalBill(totalFee);
    };

    isDesktopDevice && calculateTotalFee();
  }, [formData.skill, skills, formData.specialization]);

  useEffect(() => {
    // Calculate final bill when formData.discount or totalBill changes
    const calculateFinalBill = () => {
      const discount = formData.discount || 0; // Ensure discount is not null
      const finalAmount = totalBill - discount;
      setFinalBill(finalAmount >= 0 ? finalAmount : 0); // Ensure final amount is not negative
    };

    isDesktopDevice && calculateFinalBill();
  }, [formData.discount, totalBill]);

  const handleChange = (event, value, name) => {
    // console.log(event,value,name);
    
    let newValue;
    if (name === "userId") {
      // For the userId field, store user_id
      newValue = value?value:[]
    } else if (name === "discount") {
      // For the discount field, store the entered value directly
      newValue = value ? parseInt(value, 10) : null;
    } else if (name === 'skill') {
      // For other fields (e.g., skill), store their IDs
      newValue = value ? value.map((item) => item.skill_id) : null;
    } else {
      newValue = value ? value.map((item) => item.specialization_id) : null;
    }
    setFormData((prevState) => ({
      ...prevState,
      [event.target.name || name]: newValue,
    }));
  };
  console.log(formData);
  
  const handleSubmit = async () => {
    const { skill, discount, userId, specialization } = formData;
    console.log(skill, specialization, discount, userId);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/enrollment/add-to-cart`, {
        user_id: userId.map(val=>val.user_id),
        skill_ids: skill,
        discount: parseInt(discount),
        specialization_ids: specialization,
        emp_id: empId
      });

      if (response.status === 201) {
        console.log("Items added to cart successfully");
        toast.success("Items added to cart successfully")
        // Reset form data
        setFormData(prev=>({
          skill: [],
          specialization:[],
          userId: "",
          discount: "",
        }));
        setResetInputField((prev) => !prev);
      } else {
        console.error("Error adding items to cart: ", response.data.message);
        toast.error(response.data.message)
      }
    } catch (error) {
      console.error("Error adding items to cart: ", error);
      toast.error(error.response.data.message)
    }
  };
  const [resetInputField, setResetInputField] = useState(false);
  const handleCancel = () => {
    // Reset form data on cancel
    setFormData({
      skill: [],
      specialization: [],
      userId: "",
      discount: "",
    });
    setResetInputField((prev) => !prev);
    setTotalBill(0)
  };

  if (!isAuthorized) {
    return (
      <div>
        <h2 style={{marginTop: '5%'}}>You are Unauthorized to access this page</h2>
      </div>
    )
  }

  return (
    <>
      {
        isDesktopDevice ? (
          <div style={{ paddingTop: '4rem' }}>
            <Card elevation={3} className="col-md-12 p-3 d-flex mb-3" style={{ boxShadow: 'none' }}>
              <CardContent className="d-flex flex-column w-100">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h2 className="page-header">User Cart</h2>
                  <div>
                    <Button
                      variant="contained"
                      type="submit"
                      onClick={handleCancel}
                      style={{ width: '15.385vw', height: '2.564vw', background: 'none', border: '2.03px solid #37446F', color: 'black', marginRight: '8px', textTransform: 'none' }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      style={{ width: '15.385vw', background: '#37446F', height: '2.564vw', color: 'white', textTransform: 'none' }}
                    >
                      Add to Cart
                    </Button>
                  </div>
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '5.128vw', width: '69.231vw', margin: 'auto' }}>
                <form onSubmit={handleSubmit}>
                  {/* Users */}
                  <>
                    <Autocomplete
                      key={resetInputField}
                      multiple
                      options={users}
                      getOptionLabel={(option) => option.username}
                      onChange={(event, value) => handleChange(event, value, "userId")}
                      renderInput={(params) => (
                        <TextField
                          id="userId"
                          {...params}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="Username"
                          className='customTextfield'
                          required
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'transparent',
                              },
                              '&:hover fieldset': {
                                borderColor: 'transparent',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'transparent',
                                boxShadow: 'none',
                              },
                            }
                          }}
                          InputLabelProps={{
                            sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                            required: false
                          }}
                        />
                      )}
                    />
                  </>
                  {/* Specialization */}
                  <>
                    <Autocomplete
                      key={resetInputField}
                      multiple
                      disableCloseOnSelect={true}
                      options={specialization}
                      getOptionLabel={(option) => option.specialization_name}
                      onChange={(event, value) => handleChange(event, value, "specialization")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          focusColor='black'
                          label="Specialization"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          className='customTextfield'
                          required
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'transparent',
                              },
                              '&:hover fieldset': {
                                borderColor: 'transparent',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'transparent',
                                boxShadow: 'none',
                              },
                            }
                          }}
                          InputLabelProps={{
                            sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                            required: false
                          }}
                        />
                      )}
                    />
                  </>
                  
                  {/* Skills */}
                  <>
                    <Autocomplete
                      key={resetInputField}
                      multiple
                      disableCloseOnSelect={true}
                      options={skills}
                      getOptionLabel={(option) => option.skill_name}
                      onChange={(event, value) => handleChange(event, value, "skill")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          focusColor='black'
                          label="Skills"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          className='customTextfield'
                          required
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'transparent',
                              },
                              '&:hover fieldset': {
                                borderColor: 'transparent',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'transparent',
                                boxShadow: 'none',
                              },
                            }
                          }}
                          InputLabelProps={{
                            sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                            required: false
                          }}
                        />
                      )}
                    />
                  </>
                  {/* Discount */}
                  <>
                    <TextField
                      key={resetInputField}
                      className='customTextfield'
                      label="Discount"
                      type="number"
                      id="discound"
                      value={formData.discount}
                      onChange={(event) =>
                        handleChange(event, event.target.value, "discount")
                      }
                      fullWidth
                      margin="normal"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'transparent',
                          },
                          '&:hover fieldset': {
                            borderColor: 'transparent',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'transparent',
                            boxShadow: 'none',
                          },
                        }
                      }}
                      InputLabelProps={{
                        sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                        required: false
                      }}
                    />
                  </>
                </form>
                <div className="billContainer">
                  <div className="billTop" style={{ border: '1px solid #37446F' }}>
                    <div className="billTitles">
                      <div>Item Total</div>
                      <div>Additional Discount</div>
                    </div>
                    <div className="billAmount">

                            <div>{totalBill}</div>
                            <div>-{formData.discount}</div>
                          </div>
                        </div>
                        <div className="billBottom" style={{ border: '1px solid #37446F' }}>
                          <div>Total</div>
                          <div>{finalBill}</div>
                        </div>
                      </div>
                </div>
              </CardContent>
            </Card>
          </div>
        ) : (
          <UserCartMobile />
        )
      }
    </>
  );
};

export default UserCart;
